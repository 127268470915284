<!-- <ion-slides [options]="slidesOption">
  <ion-slide *ngFor="let ticket of ticketInformationList; let i = index"> -->
<div>
  <div>
    <ion-row paddingTop>
      <ion-col size="2"></ion-col>
      <ion-col size="8" flexCenter noMargin>
        <ion-avatar>
          <img src="../../../assets/images/icon-sent.svg" />
        </ion-avatar>
      </ion-col>
      <ion-col flexTopRight size="2">
        <ion-text (click)="dismiss()" red>Close</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col flexCenter noMargin marginTop>
        <ion-text secondary3>
          Email Sent
        </ion-text>
      </ion-col>
    </ion-row>
  </div>

  <div center emailTicket>
    <ion-row justify-content-center>
      <ion-text secondary4>
        Your certificate has been sent to your email.
      </ion-text>
    </ion-row>
  </div>

  <ion-row>
    <ion-col flexCenter padding>
      <ion-text green (click)="goToProfile()">
        Back to Profile
      </ion-text>
    </ion-col>
  </ion-row>