import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
const VENEUS_COLLECTION = environment.dev ? 'venues-dev' : 'venues';
export class VenueService {
    constructor(afs) {
        this.afs = afs;
    }
    getVenue() {
        return this.afs.collection(VENEUS_COLLECTION, ref => ref.orderBy('title')).snapshotChanges();
    }
    addVenue(venue) {
        return this.afs.collection(VENEUS_COLLECTION).add(venue);
    }
    updateVenue(venue) {
        delete venue.id;
        this.afs.doc(VENEUS_COLLECTION + '/' + venue.id).update(venue);
    }
    deleteVenue(venueId) {
        this.afs.doc(VENEUS_COLLECTION + '/' + venueId).delete();
    }
}
VenueService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VenueService_Factory() { return new VenueService(i0.ɵɵinject(i1.AngularFirestore)); }, token: VenueService, providedIn: "root" });
