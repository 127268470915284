import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { SucceedTicketModalComponent } from '../succeed-ticket-modal/succeed-ticket-modal.component';
import { TicketModal } from '../../modal/ticket-modal';
import { TicketService } from '../../services/ticket.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavExtrasService } from 'src/app/services/nav-extras.service';
// import { uuid } from 'uuidv4';
import * as uuid from 'uuid';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoadingService } from 'src/app/services/loading-service.service';
import { TermServicesComponent } from '../../components/term-services/term-services.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent implements OnInit {

  quantities = [];
  fullname: string[] = [''];
  email: string[] = [''];
  phoneNumber: string[] = [''];
  checkmark: boolean[] = [];
  expand: boolean[] = [];
  ticketDetailsList;
  valid: boolean = false;
  ticketQuantity: number;
  newsletter: boolean[] = [];
  campusTour: boolean[] = [];
  contactName: string = '';
  contactEmail: string = '';
  tnc: boolean = false;
  campus: boolean = false;
  eventId: string;
  currentTimeInMilliseconds = Date.now();
  uid: string[] = [''];

  constructor(
    private modalCtrl: ModalController,
    private ticketService: TicketService,
    private afs: AngularFirestore,
    private alertCtrl: AlertController,
    private navExtraService: NavExtrasService,
    private firebaseService: FirebaseService,
    private router: Router,
    private loadingService: LoadingService,
    private inAppBrowser: InAppBrowser
  ) {

  }

  async ionViewWillEnter() {
    const user = await this.firebaseService.firebaseOnAuth();
    if (user) {

      this.contactName = user.displayName;
      this.contactEmail = user.email;
      this.fullname[0] = user.displayName;
      this.email[0] = user.email;
      this.phoneNumber[0] = user.phoneNumber;
      this.expand[0] = true;
      this.uid[0] = user.uid;
    }
  }

  ngOnInit() {
    for (let i = 0; i < this.ticketQuantity; i++) {
      this.quantities.push(1);
      this.newsletter.push(false);
      this.campusTour.push(false);
      this.checkmark.push(false);
      this.expand.push(false);
    }
  }

  dismiss() {
    this.modalCtrl.dismiss().then(() => {
      this.modalCtrl.dismiss();
    });
  }

  back() {
    this.modalCtrl.dismiss();
  }

  async submit() {
    // console.log('full', this.fullname);

    const ticketList: Array<TicketModal> = [];
    const ticket = this.navExtraService.getTicketExtra();
    for (let i = 0; i < this.ticketQuantity; i++) {
      const uniqueId = uuid.v1();
      const ticketDetails: TicketModal = {
        created_at: '',
        email: this.email[i],
        event: {
          author: ticket.author,
          endDate: ticket.end,
          id: ticket.eventId,
          startDate: ticket.date,
          title: ticket.title,
          venue: ticket.location
        },
        name: this.fullname[i],
        order_no: '',
        phone_no: this.phoneNumber[i],
        purchased_by: this.contactEmail,
        qr_code: uniqueId,
        ticket_url: environment.dev ?
          `https://dev-pls-aiu.web.app/ticket/${ticket.eventId}/${uniqueId}` : `https://pls.aiu.edu.my/ticket/${ticket.eventId}/${uniqueId}`,
        campus_tour: this.campusTour[i],
        newsletter: this.newsletter[i],
        bcc: '',
        id: '',
        seats: ticket.seat,
        uid: '',
        dateCreated: new Date()
      };
      ticketList.push(ticketDetails);
    }
    const valid = this.validationChecking(ticketList);

    // console.log('ticketList', ticketList);

    if (valid) {
      this.loadingService.present();
      const remainingTicketQuantity = await this.checkTicketQuantity();
      const duplicateCheck = await this.checkDuplicateEmail();
      const ticketSufficient = this.ticketQuantity <= remainingTicketQuantity ? true : false;

      if (ticketSufficient) {
        if (!duplicateCheck) {
          try {
            for (const index in ticketList) {
              console.log('Does not exist.');
              this.ticketService.addTicket(ticketList[index]);

              if (index === (ticketList.length - 1).toString()) {
                this.modalCtrl.dismiss().then(async () => {
                  this.modalCtrl.dismiss().then(async () => {
                    const modal = this.modalCtrl.create({
                      component: SucceedTicketModalComponent,
                      cssClass: 'ticket-succeed',
                      componentProps: {
                        ticketInformationList: ticketList,
                        contactName: this.contactName,
                        contactEmail: this.contactEmail,
                        eventId: ticket.eventId
                      }
                    });

                    this.loadingService.dismiss();

                    (await modal).present();
                  });
                });
              }
            }
          }
          catch (error) {
            console.error('ticketSubmit error:', error);
            this.loadingService.dismiss();
          }
        }
        else {
          this.loadingService.dismiss();
          const alert = this.alertCtrl.create({
            message: `Duplicate email with (${this.email})! Please use another email address.`,
            buttons: ['OK']
          });
          (await alert).present();
        }
      }
      else {
        this.loadingService.dismiss();

        const alert = this.alertCtrl.create({
          message: remainingTicketQuantity === 0 ? 'Sorry, the tickets are sold out.'
            : `Sorry, only ${remainingTicketQuantity} seat(s) left.`,
          buttons: ['Ok']
        });

        (await alert).present();
      }

    }
    else {
      const alert = this.alertCtrl.create({
        message: 'Please enter valid input.',
        buttons: ['OK']
      });
      (await alert).present();
    }
  }

  async timesUp(event) {
    if (event.action === 'done') {
      const alert = this.alertCtrl.create({
        header: 'Message',
        message: 'Time limit reached.',
        buttons: ['OK']
      });
      (await alert).present();
      this.modalCtrl.dismiss();
    }
  }

  validationChecking(ticketArray: Array<TicketModal>) {
    const regex = new RegExp('[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})');
    let regexCheck = false;
    let duplicate = 0;
    let required = 0;
    let emailChecker = '';
    let fullnameChecker = '';
    let phoneNumberChecker = '';
    for (const ticket of ticketArray) {
      if (ticket.email === emailChecker
        || ticket.name === fullnameChecker
        || ticket.phone_no === phoneNumberChecker) {
        duplicate++;
      }

      if (ticket.email === '' || ticket.name === '' || ticket.phone_no === '') {
        required++;
      }

      regexCheck = regex.test(ticket.email);

      emailChecker = ticket.email;
      fullnameChecker = ticket.name;
      phoneNumberChecker = ticket.phone_no;
    }

    // console.log('dup', duplicate, 'required', required, 'regex', regexCheck);
    return (duplicate === 0 && required === 0 && regexCheck);
  }

  async promptConfirmation() {
    const actionSheet = this.alertCtrl.create({
      header: 'Confirmation',
      message: ' Are you sure the information is correct?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            this.alertCtrl.dismiss();
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.submit();
          }
        }

      ]
    });

    (await actionSheet).present();
  }

  checkInput() {
    let validCounter = 0;
    if (this.contactEmail && this.contactName) {
      for (let i = 0; i < this.ticketQuantity; i++) {
        if (this.fullname[i] && this.email[i] && this.phoneNumber[i]) {
          validCounter++;
          this.checkmark[i] = true;
        }
        else {
          this.checkmark[i] = false;
        }
      }

      if (validCounter.toString() === this.ticketQuantity.toString()) {
        this.valid = true;
      }
      else {
        this.valid = false;
      }
    }
    else {
      this.valid = false;
    }
  }

  checkInputName(ev, index) {
    const name = ev.detail.value;

    if (name !== '') {
      const capitalizeName = name.split(' ').map(word => {
        return word.replace(word[0], word[0].toUpperCase());
      }).join(' ');

      this.fullname[index] = capitalizeName;
    }

    let validCounter = 0;
    if (this.contactEmail && this.contactName) {
      for (let i = 0; i < this.ticketQuantity; i++) {
        if (this.fullname[i] && this.email[i] && this.phoneNumber[i]) {
          validCounter++;
          this.checkmark[i] = true;
        }
        else {
          this.checkmark[i] = false;
        }
      }

      if (validCounter.toString() === this.ticketQuantity.toString()) {
        this.valid = true;
      }
      else {
        this.valid = false;
      }
    }
    else {
      this.valid = false;
    }
  }


  checkDuplicateEmail(): Promise<boolean> {
    const ticketCollection = environment.dev ? 'tickets-dev' : 'tickets';
    const ticket = this.navExtraService.getTicketExtra();

    return new Promise((resolve, reject) => {
      try {
        this.afs.collection(ticketCollection, ref => ref.where('event.id', '==', ticket.eventId)
          .where('email', 'in', this.email)).get().subscribe(res => {
            if (!res.empty) {
              resolve(true);
            }
            else {
              resolve(false);
            }
          });
      }
      catch (error) {
        console.error('checkDuplicateEmail error:', error);
        reject(error);
      }
    });
  }

  expands(index) {
    if (this.expand[index] == true) {
      this.expand[index] = !this.expand[index];
    }
    else {
      for (let i = 0; i < this.expand.length; i++) {
        this.expand[i] = false;
      }
      this.expand[index] = true;
    }
  }

  checkTnc() {
    this.tnc = !this.tnc;
    for (let i = 0; i < this.ticketQuantity; i++) {
      this.newsletter[i] = !this.newsletter[i];
    }
  }

  checkCampus() {
    this.campus = !this.campus;
    for (let i = 0; i < this.ticketQuantity; i++) {
      this.campusTour[i] = !this.campusTour[i];
    }
  }

  async goToTerms() {
    const modal = this.modalCtrl.create({
      component: TermServicesComponent,
    });

    (await modal).present();
  }

  async goToPrivacy() {
    const modal = this.modalCtrl.create({
      component: PrivacyComponent
    });

    (await modal).present();
  }

  async checkTicketQuantity() {
    let ticketQty: number;
    const ticket = this.navExtraService.getTicketExtra();
    const eventCollection = environment.dev ? 'events-dev' : 'events';
    const checkTicketQuantity = await this.afs.collection(eventCollection, query =>
      query.where('id', '==', ticket.eventId)).get().toPromise().then(data => {
        if (!data.empty) {
          data.forEach(async (res) => {
            ticketQty = res.data().tickets.availTicket;
          });
        }
      });

    return ticketQty;
  }

  openCampusTour() {
    window.open('https://firebasestorage.googleapis.com/v0/b/aiu-event-management.appspot.com/o/campus%20tour%2FMini%20Campus%20Tour%20-%20Walking%20Trail%20v1%20(17%20Feb%202020).pdf?alt=media&token=e9491c22-d4fc-4d6e-bfda-cda55b605f32', '_system', 'location=yes');
  }
}
