import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { NavExtrasService } from './nav-extras.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const tableName = '102rYXYzi-iPMHlxMoFFKwgAB7XHDF1vTNssUm828gn0';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private navCtrl: NavController,
    private navExtra: NavExtrasService,
    private httpClient: HttpClient
  ) { }

  signOut(loginBackRoutes: string, loginRoutes: string) {
    firebase.auth().signOut().then(async () => {
      await this.navExtra.setExtras({ backRoute: loginBackRoutes, loginRoute: loginRoutes });
      this.navCtrl.navigateRoot('login');
    });
  }

  getCurrentUser(): firebase.User {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      return currentUser;
    }
    else {
      return null;
    }
  }

  getFirebaseRef(address: string): firebase.database.Reference {
    return firebase.database().ref(`${tableName}/${address}`);
  }

  firebaseOnAuth(): Promise<firebase.User> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        try {
          if (user) {
            resolve(user);
          }
          else {
            resolve(null);
          }
        }
        catch (error) {
          console.error('firebaseOnAuth error:', error);
          reject(error);
        }
      });
    });
  }

  resetPassword(email: string) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  registerUser(requestBody) {
    return new Promise((resolve, reject) => {
      try {
        const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/createUser';
        const header = new HttpHeaders().set('Content-Type', 'application/json');
        const option = {
          headers: header
        };

        this.httpClient.post(URL, requestBody, option).subscribe(res => {
          resolve(res);
        }, error => {
          console.error('registerUser error:', error);
          reject(error);
        });
      }
      catch (error) {
        console.error('registerUser error:', error);
        reject(error);
      }
    });
  }

  sendTicketDetailsToEmail(requestBody) {
    console.log('requestBody', requestBody);

    return new Promise((resolve, reject) => {
      const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/sendPurchaseComfirmationEmail';
      const header = new HttpHeaders().set('Content-Type', 'application/json');
      const option = {
        headers: header
      };

      this.httpClient.post(URL, requestBody, option).subscribe(res => {
        resolve(res);
      }, error => {
        console.error('sendTicketDetailsToEmail error:', error);
        reject(error);
      });
    });
  }

  sendCertificateToEmail(requestBody) {
    return new Promise((resolve, reject) => {
      const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/sendUserCertificate';
      const header = new HttpHeaders().set('Content-Type', 'application/json');
      const option = {
        headers: header
      };

      this.httpClient.post(URL, requestBody, option).subscribe(res => {
        resolve(res);
      }, error => {
        console.error('sendCertificateToEmail error:', error);
        reject(error);
      });
    });
  }

  updateUser(requestBody) {
    return new Promise((resolve, reject) => {
      const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/updateUser';
      const header = new HttpHeaders().set('Content-Type', 'application/json');
      const option = {
        headers: header
      };

      this.httpClient.post(URL, requestBody, option).subscribe(res => {
        resolve(res);
      }, error => {
        console.error('updateUser error:', error);
        reject(error);
      });
    });
  }

  subscribeToToken(requestBody) {
    return new Promise((resolve, reject) => {
      const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/subscribeTopic';
      const header = new HttpHeaders().set('Content-Type', 'application/json');
      const option = {
        headers: header
      };
      this.httpClient.post(URL, requestBody, option).subscribe(res => {
        console.log('subscribeToToken response:', res);
        resolve(res);
      }, error => {
        console.error(error);
        resolve(error);
      });
    });
  }

}
