<div stripbar></div>

<ion-header no-border>
  <ion-toolbar mode="ios">
    <ion-title *ngIf="username">
      <ion-text primary1>{{ username }}</ion-text>
    </ion-title>
    <ion-avatar class="logo" *ngIf="homeLogo" toolbar slot="start">
      <img src='../../../assets/icon/favicon.png' />
    </ion-avatar>

    <!-- <ion-icon green> -->
    <img backIcon (click)="back()" *ngIf="!homeLogo" toolbar slot="start"
      src="../../../assets/images/icon-back@2x.png" />
    <p title *ngIf="!username" slot='start'>
      <b *ngIf="!pageTitle">
        <ion-text homeText>
          Public Lecture Series
        </ion-text>
      </b>
      <b *ngIf="pageTitle">
        <ion-text pageTitle green>
          {{ pageTitle }}
        </ion-text>
      </b>
    </p>
    <!-- </ion-icon> -->

    <ion-button class='eventMng' fill='clear' slot='start' style='width: 58px;' (click)='goToEvent()'
      *ngIf='desktop && admin'>Events</ion-button>
    <ion-button class='venueMng' fill='clear' slot='start' style='width: 85px;' (click)='goToVenue()'
      *ngIf='desktop && admin'>Venues</ion-button>
    <ion-button class='bannerMng' fill='clear' slot='start' style='width: 85px;' (click)='goToBanner()'
      *ngIf='desktop && admin'>Banners
    </ion-button>
    <ion-button class='pushMng' fill='clear' slot='start' style='width: auto;' (click)='goToPush()'
      *ngIf='desktop && admin'>Push Notifications
    </ion-button>
    <ion-button class='calendar' fill='clear' slot='start' style='width: 85px;' (click)='goToCalendar()'
      *ngIf='desktop && admin'>Calendar
    </ion-button>

    <ion-text signIn toolbar2 green slot="end" (click)="login()" *ngIf="!currentId && !termsAndPrivacy">Sign In
    </ion-text>
    <!-- <ion-avatar profile></ion-avatar> -->
    <div slot="end" *ngIf="currentId && !termsAndPrivacy" (click)="showMenu()">
      <ion-row>
        <ion-col noPaddingLeftRight>
          <ion-avatar profile>
            <img [src]="profilePictureURL" />
          </ion-avatar>
        </ion-col>
        <ion-col noPaddingLeftRight flexCenter>
          <ion-icon green name="arrow-dropdown"></ion-icon>
        </ion-col>
      </ion-row>
    </div>
  </ion-toolbar>

  <div dropdown *ngIf='dropdownMenu'>
    <div *ngIf="admin; else commonMenu">
      <ion-row margin justify-content-center>
        <ion-button grey routerLink="/admin/events" routerLinkActive="active-link" (click)='goToEvent()' fill='clear'
          *ngIf='admin'>
          Events
        </ion-button>
      </ion-row>
      <ion-row margin justify-content-center>
        <ion-button grey routerLink="/admin/venues" routerLinkActive="active-link" (click)='goToVenue()' fill='clear'
          *ngIf='admin'>
          Venues
        </ion-button>
      </ion-row>
      <ion-row margin justify-content-center>
        <ion-button grey routerLink="/admin/banners" routerLinkActive="active-link" (click)='goToBanner()' fill='clear'
          *ngIf='admin'>
          Banners
        </ion-button>
      </ion-row>
      <ion-row margin justify-content-center>
        <ion-button grey routerLink="/admin/push-notification" routerLinkActive="active-link" (click)='goToPush()'
          fill='clear' *ngIf='admin'>
          Push Notifications
        </ion-button>
      </ion-row>
      <ion-row margin justify-content-center>
        <ion-button grey routerLink="/admin/calendar" routerLinkActive="active-link" (click)='goToCalendar()'
          fill='clear' *ngIf='admin'>
          Calendar
        </ion-button>
      </ion-row>
      <ion-row margin justify-content-center>
        <ion-button (click)="logout()" fill='clear' style='color: grey; font-weight: bold;'>
          Log Out
        </ion-button>
      </ion-row>
    </div>
    <ng-template #commonMenu>
      <ion-row margin justify-content-center>
        <ion-button text-capitalize grey routerLink="/event-home" routerLinkActive="active-link"
          (click)="navigateHome()" fill='clear'>
          Home
        </ion-button>
      </ion-row>
      <ion-row *ngIf="notificationCount <= 0" margin justify-content-center>
        <ion-button text-capitalize grey routerLink="/notification" routerLinkActive="active-link"
          (click)="goToNotifications()" fill='clear'>
          Notifications
        </ion-button>
      </ion-row>
      <ion-row *ngIf="notificationCount > 0" margin align-items-center justify-content-center>
        <!-- <ion-col noMargin size="3.5"></ion-col>
        <ion-col noMargin size="5"> -->
        <ion-button style="padding-top: 8px;" grey routerLink="/notification" routerLinkActive="active-link" (click)="goToNotifications()"
          fill='clear'>
          <ion-text text-capitalize>
            Notifications
          </ion-text>
        </ion-button>
        <!-- </ion-col> -->
        <!-- <ion-col flexLeft noMargin size="3.5"> -->
        <div test>
          <ion-avatar notification>
            {{ notificationCount }}
          </ion-avatar>
        </div>
        <!-- </ion-col> -->
      </ion-row>
      <ion-row margin justify-content-center>
        <ion-button text-capitalize grey routerLink="/profile-ticket" routerLinkActive="active-link"
          (click)="goToProfileTicket()" fill='clear'>
          Tickets
        </ion-button>
      </ion-row>
      <ion-row margin justify-content-center>
        <ion-button text-capitalize grey routerLink="/profile" routerLinkActive="active-link" (click)="goToProfile()"
          fill='clear'>
          Profile
        </ion-button>
      </ion-row>
      <ion-row margin justify-content-center>
        <ion-button text-capitalize grey (click)="logout()" fill='clear' style='color: grey; font-weight: bold;'>
          Log Out
        </ion-button>
      </ion-row>
    </ng-template>
  </div>
</ion-header>



<!-- <section homeImage *ngIf='desktop'>
</section> -->

<!-- <section class='margin' *ngIf='desktop'>
  <div>
    <ion-grid>
      <ion-row>
        <ion-col size='2'>
          <img src='../../../assets/icon/logo.png' class='logos' />
        </ion-col>
        <ion-col size='10'>
          <div class='navButton'>
            <ion-button class='eventNav' fill='clear' style='width: 58px;'>Events</ion-button>
            <ion-button class='myEventNav' fill='clear' style='width: 85px;'>My Events</ion-button>
            <ion-button class='speakerNav' fill='clear' style='width: 80px;'>Speakers</ion-button>
            <ion-button class='chatNav' fill='clear' style='width: 45px;'>Chat</ion-button>
            <ion-button class='downloadNav' fill='clear' style='width: 98px;'>Downloads</ion-button>
            <ion-button class='pollNav' fill='clear' style='width: 42px;'>Polls</ion-button>
            <ion-button class='ticketNav' fill='clear' style='width: 53px;'>Ticket</ion-button>
            <ion-button class='signInNav' fill='clear' style='width: 58px;'>Sign In</ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</section> -->