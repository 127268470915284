import { Component } from '@angular/core';

@Component({
  selector: 'app-term-services',
  templateUrl: './term-services.component.html',
  styleUrls: ['./term-services.component.scss'],
})
export class TermServicesComponent {

  constructor() { }

  goToTop() {
    document.querySelector('#top').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
