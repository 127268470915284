<app-header pageTitle="Terms of Services" termsAndPrivacy="true"></app-header>
<ion-content>
  <div>
    <p id='top'><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Please read these terms of
        services carefully before using Our Service.</span></p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span style="font-family: 'Poppins', serif;">Interpretation
                and Definitions</span></span></span></strong></span></p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Interpretation</span></span></h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The words of which the
        initial letter is capitalized have meanings defined under the following conditions.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The following definitions
        shall have the same meaning regardless of whether they appear in singular or in plural.</span></p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Definitions</span></span></h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">For the purposes of these
        Terms of Services:</span></p>
    <ul>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Affiliate</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> means an entity that
              controls, is controlled by or is under common control with a party, where "control" means ownership of 50%
              or more of the shares, equity interest or other securities entitled to vote for election of directors or
              other managing authority.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Company</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> (referred to as either "the
              Company", "We", "Us" or "Our" in this Agreement) refers to Albukhary International
              University.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Country</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> refers to:
              Malaysia</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Device</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> means any device that can
              access the Service such as a computer, a cellphone or a digital tablet.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Service</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> refers to the
              Website.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Terms and Conditions</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> (also referred as "Terms")
              mean these Terms and Conditions that form the entire agreement between You and the Company regarding the
              use of the Service. This Terms and Conditions agreement as been created with the help of Terms and
              Conditions Generator.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Third-party Social Media Service</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> means any services or
              content (including data, information, products or services) provided by a third-party that may be
              displayed, included or made available by the Service.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Website</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> refers to Albukhary
              International University, accessible from <span style="color: #78BE3C;"><a
                  href="http://www.aiu.edu.my">http://www.aiu.edu.my</a></span></span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>You</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> mean the individual
              accessing or using the Service, or the company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.</span></span></span></li>
    </ul>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px; color: #000000;"><strong><span style="font-family: Calibri, serif;"><span
              style="font-family: 'Poppins', serif;">Acknowledgement</span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">These are the Terms of
        Services governing the use of this Service and the agreement that operates between You and the Company. These
        Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</span>
    </p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Your access to and use of
        the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and
        Conditions apply to all visitors, users and others who access or use the Service.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">By accessing or using the
        Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and
        Conditions, then You may not access the Service.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">You represent that you are
        over the age of 18. The Company does not permit those under 18 to use the Service.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Your access to and use of
        the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal
        information when You use the Application or the Website and tells You about Your privacy rights and how the law
        protects You. Please read Our Privacy Policy carefully before using Our Service.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px; color: #000000;"><strong><span style="font-family: Calibri, serif;"><span
              style="font-family: 'Poppins', serif;">Links to Other Websites</span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Our Service may contain
        links to third-party web sites or services that are not owned or controlled by the Company.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The Company has no control
        over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web
        sites or services. You further acknowledge and agree that the Company shall not be responsible or liable,
        directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use
        of or reliance on any such content, goods or services available on or through any such web sites or
        services.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We strongly advise You to
        read the terms and conditions and privacy policies of any third-party web sites or services that You
        visit.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span
                style="font-family: 'Poppins', serif;">Termination</span></span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We may terminate or
        suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without
        limitation if You breach these Terms and Conditions.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Upon termination, your
        right to use the Service will cease immediately.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span style="font-family: 'Poppins', serif;">Limitation of
                Liability</span></span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Notwithstanding any
        damages that You might incur, the entire liability of the Company and any of its suppliers under any provision
        of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by
        You through the Service.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">To the maximum extent
        permitted by applicable law, in no event shall the Company or its suppliers be liable for any special,
        incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of
        profits, loss of data or other information, for business interruption, for personal injury, loss of privacy
        arising out of or in any way related to the use of or inability to use the Service, third-party software and/or
        third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even
        if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails
        of its essential purpose.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Some states do not allow
        the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which
        means that some of the above limitations may not apply. In these states, each party's liability will be limited
        to the greatest extent permitted by law.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px; color: #000000;"><strong><span style="font-family: Calibri, serif;"><span
              style="font-family: 'Poppins', serif;">"AS IS" and "AS AVAILABLE"
              Disclaimer</span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The Service is provided to
        You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum
        extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its
        and their respective licensors and service providers, expressly disclaims all warranties, whether express,
        implied, statutory or otherwise, with respect to the Service, including all implied warranties of
        merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out
        of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the
        Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet
        Your requirements, achieve any intended results, be compatible or work with any other software, applications,
        systems or services, operate without interruption, meet any performance or reliability standards or be error
        free or that any errors or defects can or will be corrected.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Without limiting the
        foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any
        kind, express or implied: (i) as to the operation or availability of the Service, or the information, content,
        and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as
        to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv)
        that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of
        viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Some jurisdictions do not
        allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer,
        so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions
        and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable
        law.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span style="font-family: 'Poppins', serif;">Governing
                Law</span></span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The laws of the Country,
        excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the
        Application may also be subject to other local, state, national, or international laws.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span style="font-family: 'Poppins', serif;">Disputes
                Resolution</span></span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">If You have any concern or
        dispute about the Service, you agree to first try to resolve the dispute informally by contacting the
        Company.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px; color: #000000;"><strong><span style="font-family: Calibri, serif;"><span
              style="font-family: 'Poppins', serif;">Severability and Waiver</span></span></strong></span></p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Severability</span></span></h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">If any provision of these
        Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under applicable law and the remaining provisions
        will continue in full force and effect.</span></p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Waiver</span></span>
    </h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Except as provided herein,
        the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a
        party's ability to exercise such right or require such performance at any time thereafter nor shall be the
        waiver of a breach constitute a waiver of any subsequent breach.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span style="font-family: 'Poppins', serif;">Translation
                Interpretation</span></span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">These Terms of Services
        may have been translated if We have made them available to You on our Service.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">You agree that the
        original English text shall prevail in the case of a dispute.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span style="font-family: 'Poppins', serif;">Changes to These
                Terms of Services</span></span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We reserve the right, at
        Our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will make
        reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a
        material change will be determined at Our sole discretion.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">By continuing to access or
        use Our Service after those revisions become effective, you agree to be bound by the revised terms. If You do
        not agree to the new terms, in whole or in part, please stop using the website and the Service.</span></p>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span style="font-family: 'Poppins', serif;">Contact
                Us</span></span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">If you have any questions
        about these Terms of Services, you can contact us:</span></p>
    <ul>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">By email: <span
            style="color: #78BE3C;"><a href="mailto:pls1@aiu.edu.my">pls1@aiu.edu.my</a></span></span></li>
    </ul>
  </div>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button (click)="goToTop()">
      <img src="../../../assets//images//icon-backtop.svg" />
    </ion-fab-button>
  </ion-fab>
</ion-content>