import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavExtrasService } from 'src/app/services/nav-extras.service';
import { ModalController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { environment } from '../../../environments/environment';
export class SucceedTicketModalComponent {
    constructor(navExtraService, modalCtrl, afs, router, firebaseService) {
        this.navExtraService = navExtraService;
        this.modalCtrl = modalCtrl;
        this.afs = afs;
        this.router = router;
        this.firebaseService = firebaseService;
        this.slidesOption = {
            slidesPerView: 1,
            centeredSlides: true
        };
        this.ticketOrderList = [];
        this.tickets = new Array();
        this.ticketBodyArray = [];
        this.getTicketDetails();
        this.getConfirmDetails();
    }
    ngOnInit() {
    }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ticketOrderList = [];
            const ticketCollection = environment.dev ? 'tickets-dev' : 'tickets';
            const ticketBodyArray = [];
            for (const ticket of this.ticketInformationList) {
                const promise = yield this.afs.collection(ticketCollection, ref => ref.where('email', '==', ticket.email)
                    .where('event.id', '==', this.eventId)).get().forEach(data => {
                    data.forEach(res => {
                        this.ticketOrderList.push(res.id);
                        ticket['orderNumber'] = res.id;
                        const ticketBody = {
                            email: ticket.email,
                            name: ticket.name,
                            orderId: ticket.orderNumber,
                        };
                        ticketBodyArray.push(ticketBody);
                    });
                });
            }
            console.log('ticketBody', ticketBodyArray);
            const requestBody = {
                event_name: this.ticketDetails.title,
                contact: {
                    name: this.contactName,
                    email: this.contactEmail
                },
                tickets: ticketBodyArray
            };
            console.log('requestBody', requestBody);
            const response = yield this.firebaseService.sendTicketDetailsToEmail(requestBody);
            console.log('response:', response);
        });
    }
    getTicketDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ticketDetails = yield this.navExtraService.getTicketExtra();
        });
    }
    getConfirmDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.confirmDetails = yield this.navExtraService.getConfirmExtra();
        });
    }
    dismiss() {
        this.modalCtrl.dismiss();
    }
    goToTicket() {
        const navExtra = {
            state: {
                ticketDetailsList: this.ticketInformationList
            }
        };
        this.router.navigateByUrl(`ticket-information`, navExtra);
        this.modalCtrl.dismiss();
    }
}
