import { Component, OnInit } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { NavigationExtras, Router } from '@angular/router';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private fcm: FCM,
    private router: Router,
    private localNotification: LocalNotifications,
    private toastController: ToastController,
    private firebaseDynamicLinks: FirebaseDynamicLinks
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(async () => {

      if (!this.platform.is('desktop')) {
        const pushPermission = await this.fcm.requestPushPermission();
        console.log('Push permission is ', pushPermission);
      }

      this.firebaseDynamicLinks.onDynamicLink().subscribe((res: any) => {
        console.log(res)
        const url = res.deepLink.split("/");
        console.log("url", url);
        this.router.navigateByUrl('event-details/' + url[4]);
        this.router.navigateByUrl('login');
      }, (error: any) => console.log(error));

      this.statusBar.styleDefault();
      this.splashScreen.hide();

      if (!this.platform.is('desktop')) {
        this.fcm.onNotification().subscribe(data => {
          console.log('data:', JSON.stringify(data));
  
          if (data.wasTapped) {
            const state: NavigationExtras = {
              state: {
                targetId: data.targetId,
                targetName: data.name
              }
            };
            if (data.type === 'chat') {
              this.router.navigateByUrl(`chat-room/${data.eventId}/${data.targetId}`, state);
            }
          }
          else {
            if (this.platform.is('android')) {
              this.localNotification.schedule({
                title: data.title,
                text: data.body,
                vibrate: true,
                foreground: true
              });
            }
            else if (this.platform.is('ios')) {
              this.presentIOSToast(data);
            }
          }
        });
      }
    });
  }

  async presentIOSToast(notification) {
    const toast = this.toastController.create({
      message: notification.body,
      header: notification.title,
      duration: 3000,
      position: 'top',
      cssClass: 'toastMessage'
    });

    (await toast).present();
  }
}
