import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from './header.component';
import { LogoutPopoverComponent } from '../logout-popover/logout-popover.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HeaderComponent, LogoutPopoverComponent],
  imports: [
    IonicModule,
    CommonModule,
    AngularFireModule.initializeApp(environment),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    RouterModule
  ],
  exports: [HeaderComponent],
  entryComponents: [LogoutPopoverComponent]

})

export class HeaderComponentModule { }
