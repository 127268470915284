import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    IonicModule,
    CommonModule,
    AngularFireModule.initializeApp(environment),
    AngularFirestoreModule,
    AngularFireDatabaseModule
  ],
  exports: [FooterComponent],
  entryComponents: []

})

export class FooterComponentModule { }
