import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { VenueInfo } from '../modal/venue-modal';
import { environment } from 'src/environments/environment';

const VENEUS_COLLECTION = environment.dev ? 'venues-dev' : 'venues';

@Injectable({
  providedIn: 'root'
})

export class VenueService {

  constructor(public afs: AngularFirestore) { }

  getVenue() {
    return this.afs.collection(VENEUS_COLLECTION, ref => ref.orderBy('title')).snapshotChanges();
  }

  addVenue(venue: VenueInfo) {
    return this.afs.collection(VENEUS_COLLECTION).add(venue);
  }

  updateVenue(venue: VenueInfo) {
    delete venue.id;
    this.afs.doc(VENEUS_COLLECTION + '/' + venue.id).update(venue);
  }

  deleteVenue(venueId: string) {
    this.afs.doc(VENEUS_COLLECTION + '/' + venueId).delete();
  }
}
