import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase.service';
export class SendCertSuccessComponent {
    constructor(modalCtrl, firebaseService) {
        this.modalCtrl = modalCtrl;
        this.firebaseService = firebaseService;
        this.valid = false;
        this.email = '';
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('success email', this.email);
            console.log('success certlist', this.certList);
            const requestBody = {
                email: this.email,
                eventName: this.certList.eventName,
                certLink: this.certList.certLink
            };
            console.log('requestBody', requestBody);
            const response = yield this.firebaseService.sendCertificateToEmail(requestBody);
            console.log('response:', response);
        });
    }
    dismiss() {
        this.modalCtrl.dismiss().then(() => {
            this.modalCtrl.dismiss();
        });
    }
    goToProfile() {
        this.modalCtrl.dismiss();
    }
}
