export const environment = {
  production: true,
  firebase: {
    production: true,
    apiKey: 'AIzaSyCXANJW4YdKFjgi45wL8FxeMxXhmqkf_vs',
    authDomain: 'aiu-event-management.firebaseapp.com',
    databaseURL: 'https://aiu-event-management.firebaseio.com',
    projectId: 'aiu-event-management',
    storageBucket: 'aiu-event-management.appspot.com',
    messagingSenderId: '7881227031'
  },
  dev: false
};
