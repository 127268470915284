<ion-content>
  <div>
    <ion-row>
      <ion-col flexLeft size="2" (click)="back()">
        <img back src="../../../assets/images/icon-back@2x.png" />
      </ion-col>
      <ion-col size="8">
        <ion-row justify-content-center>
          <ion-text marginTop>
            Checkout
          </ion-text>
        </ion-row>
        <ion-row countdown justify-content-center>
          Time left &nbsp;<countdown #countdown [config]="{leftTime: 480, format: 'm:ss'}" (event)="timesUp($event)">
            $!m!:$!s!</countdown>
        </ion-row>
      </ion-col>
      <ion-col flexRight size="2" (click)="dismiss()">
        <ion-text red>
          Close
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col flexLeft>
        <b>
          Contact Information
        </b>
      </ion-col>
    </ion-row>

    <div marginBottom>
      <ion-row>
        <ion-col flexLeft>
          <ion-item black>
            <ion-label black position="stacked">
              Full Name
              <ion-text red>*</ion-text>
            </ion-label>
            <ion-input type='text' disabled="true" [(ngModel)]="contactName" (ionInput)="checkInput()"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row borderBottom>
        <ion-col flexLeft>
          <ion-item black>
            <ion-label black position="stacked">
              Email
              <ion-text red>*</ion-text>
            </ion-label>
            <ion-input type='text' disabled="true" [(ngModel)]="contactEmail" (ionChange)="checkInput()"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>

    <div borderBottom *ngFor="let ticketDetail of quantities; let i = index">
      <!-- <div left>
        <ion-text style="padding-top: 10px;">
          <b>
            Registration - Ticket {{i+1}}
          </b>
        </ion-text>
      </div> -->
      <ion-row paddingTop (click)="expands(i)">
        <ion-col noMargin size="1" flexLeft *ngIf="checkmark[i]">
          <img checkmark src="../../../assets/images/icon-mark.svg" />
        </ion-col>
        <ion-col size="9" flexLeft>
          <ion-text>
            <b>
              Registration - Ticket {{i+1}}
            </b>
          </ion-text>
        </ion-col>
        <ion-col flexRight>
          <img arrow *ngIf="expand[i]" src="../../../assets/images/icon-up-arrow@2x.png" />
          <img arrow *ngIf="!expand[i]" src="../../../assets/images/icon-down-arrow@2x.png" />
        </ion-col>
      </ion-row>
      <ng-template [ngIf]="expand[i]">
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label input position="stacked">
                Full Name
                <ion-text red>*</ion-text>
              </ion-label>
              <ion-input text-capitalize type='text' [(ngModel)]="fullname[i]" (ionChange)="checkInputName($event, i)">
              </ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label input position="stacked">
                Email
                <ion-text red>*</ion-text>
              </ion-label>
              <ion-input type='email' [(ngModel)]="email[i]" (ionChange)="checkInput()"
                pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
                (autocomplete)="false">
              </ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label input position="stacked">
                Phone Number
                <ion-text red>*</ion-text>
              </ion-label>
              <ion-input type='tel' [(ngModel)]="phoneNumber[i]" (ionChange)="checkInput()"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
      </ng-template>
    </div>

    <ion-row marginTop>
      <ion-row fullWidth>
        <ion-col flexCenter checkbox size="2">
          <ion-checkbox (click)="checkTnc()"></ion-checkbox>
        </ion-col>
        <ion-col flexLeft>
          <ion-text tnC>I agree to receive information about the latest events happening</ion-text>
        </ion-col>
      </ion-row>
      <ion-row fullWidth>
        <ion-col flexCenter size="2">
          <ion-checkbox (click)="checkCampus()"></ion-checkbox>
        </ion-col>
        <ion-col flexLeft>
          <ion-text campTour>I am interested to join <ion-text green (click)="openCampusTour()">
              AIU campus tour
            </ion-text>
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-text agree>
          By clicking "Submit", I agree the
          <ion-label primary2 (click)='goToTerms()'>
            Terms of Services
          </ion-label> and have read the
          <ion-label primary2 (click)='goToPrivacy()'>
            Privacy Policy.
          </ion-label>
          I agree that AIU may share my information with event organizer.
        </ion-text>
      </ion-col>
    </ion-row>

    <ion-row marginTop>
      <ion-col>
        <ion-button [disabled]="!valid" (click)="promptConfirmation()" green expand="full">
          <ion-text text-capitalize>
            Submit
          </ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>