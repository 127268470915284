/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./check-in-validate.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./check-in-validate.component";
var styles_CheckInValidateComponent = [i0.styles];
var RenderType_CheckInValidateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckInValidateComponent, data: {} });
export { RenderType_CheckInValidateComponent as RenderType_CheckInValidateComponent };
export function View_CheckInValidateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(2, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "ion-col", [["flexRight", ""]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(4, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(6, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(8, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 5, "ion-col", [["flexCenter", ""]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(10, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(12, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You Are Not Checked In"])), (_l()(), i1.ɵeld(15, 0, null, null, 6, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(16, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 4, "ion-col", [["flexCenter", ""]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(18, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(19, 0, null, 0, 2, "ion-label", [["message", ""]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(20, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, [" Please get your ticket validated to use this function. "]))], function (_ck, _v) { var currVal_0 = "close"; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_CheckInValidateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-check-in-validate", [], null, null, null, View_CheckInValidateComponent_0, RenderType_CheckInValidateComponent)), i1.ɵdid(1, 114688, null, 0, i4.CheckInValidateComponent, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckInValidateComponentNgFactory = i1.ɵccf("app-check-in-validate", i4.CheckInValidateComponent, View_CheckInValidateComponent_Host_0, {}, {}, []);
export { CheckInValidateComponentNgFactory as CheckInValidateComponentNgFactory };
