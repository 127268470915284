import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { NavExtrasService } from './nav-extras.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic/storage";
import * as i3 from "./nav-extras.service";
export class AuthGuardService {
    constructor(navCtrl, storage, navExtra) {
        this.navCtrl = navCtrl;
        this.storage = storage;
        this.navExtra = navExtra;
    }
    canActivate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const userId = yield this.storage.get('userId');
            if (!userId) {
                this.navExtra.setExtras(this.navExtra.getExtras());
                this.navCtrl.navigateRoot('login');
            }
            if (userId) {
                return true;
            }
        });
    }
}
AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.NavExtrasService)); }, token: AuthGuardService, providedIn: "root" });
