<app-header #header pageTitle="Privacy Policy" termsAndPrivacy="true">
</app-header>

<ion-content>
  <div>
    <p id='top'><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">This Privacy Policy
        describes
        Our policies and procedures on the collection, use and disclosure of Your information when You use the Service
        and
        tells You about Your privacy rights and how the law protects You.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We use Your Personal data to
        provide and improve the Service. By using the Service, you agree to the collection and use of information in
        accordance with this Privacy Policy.</span></p>
    <p><span style="font-size: 16px; color: #000000;"><strong><span style="font-family: Calibri, serif;"><span
              style="font-family: 'Poppins', serif;">Interpretation and Definitions</span></span></strong></span>
    </p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Interpretation</span></span></h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The words of which the
        initial
        letter is capitalized have meanings defined under the following conditions.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The following definitions
        shall have the same meaning regardless of whether they appear in singular or in plural.</span></p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Definitions</span></span></h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">For the purposes of this
        Privacy Policy:</span></p>
    <ul>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>You</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> mean the individual accessing
              or
              using the Service, or the company, or other legal entity on behalf of which such individual is accessing
              or
              using the Service, as applicable.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Company</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> (referred to as either "the
              Company", "We", "Us" or "Our" in this Agreement) refers to Albukhary International
              University.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Affiliate</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> means an entity that controls,
              is controlled by or is under common control with a party, where "control" means ownership of 50% or more
              of
              the shares, equity interest or other securities entitled to vote for election of directors or other
              managing
              authority.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Account</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> means a unique account created
              for You to access our Service or parts of our Service.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Website</strong></span></span><span style="color: #78BE3C;"><span
              style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"><span style="color: #757575;">
                  refers to Albukhary International University, accessible from </span><span style="color: #78BE3C;"><a
                    href="http://www.aiu.edu.my">http://www.aiu.edu.my</a></span></span></span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Service</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> refers to the
              Website.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Country</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> refers to:
              Malaysia</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Service Provider</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> means any natural or legal
              person who processes the data on behalf of the Company. It refers to third-party companies or individuals
              employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to
              perform
              services related to the Service or to assist the Company in analyzing how the Service is
              used.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Third-party Social Media Service</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> refers to any website or any
              social network website through which a User can log in or create an account to use the
              Service.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Personal Data</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> is any information that
              relates
              to an identified or identifiable individual.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Cookies</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> are small files that are
              placed
              on Your computer, mobile device or any other device by a website, containing the details of Your browsing
              history on that website among its many uses.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Device</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> means any device that can
              access
              the Service such as a computer, a cellphone or a digital tablet.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>Usage Data</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> refers to data collected
              automatically, either generated by the use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).</span></span></span></li>
    </ul>
    <p>&nbsp;</p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span style="font-family: 'Poppins', serif;">Collecting and
                Using
                Your Personal Data</span></span></span></strong></span></p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Types of Data
          Collected</span></span></h2>
    <h3 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Personal
          Data</span></span></h3>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">While using Our Service, we
        may ask You to provide Us with certain personally identifiable information that can be used to contact or
        identify
        You. Personally, identifiable information may include, but is not limited to:</span></p>
    <ul>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Email address</span></li>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">First name and last
          name</span></li>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Phone number</span></li>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Usage Data</span></li>
    </ul>
    <h3 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Usage
          Data</span></span></h3>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Usage Data is collected
        automatically when using the Service.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Usage Data may include
        information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version,
        the
        pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique
        device
        identifiers and other diagnostic data.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">When You access the Service
        by
        or through a mobile device, We may collect certain information automatically, including, but not limited to, the
        type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile
        operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic
        data.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We may also collect
        information that Your browser sends whenever You visit our Service or when You access the Service by or through
        a
        mobile device.</span></p>
    <h3 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Tracking
          Technologies and Cookies</span></span></h3>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We use Cookies and similar
        tracking technologies to track the activity on Our Service and store certain information. Tracking technologies
        used
        are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.</span>
    </p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">You can instruct Your
        browser
        to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, you may
        not
        be able to use some parts of our Service.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Cookies can be "Persistent"
        or
        "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline,
        while
        Session Cookies are deleted as soon as You close your web browser. Learn more about cookies: All About
        Cookies.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We use both session and
        persistent Cookies for the purposes set out below:</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;"><strong>Necessary /
          Essential
          Cookies</strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Type: Session Cookies</span>
    </p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Administered by: Us</span>
    </p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Purpose: These Cookies are
        essential to provide You with services available through the Website and to enable You to use some of its
        features.
        They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services
        that You have asked for cannot be provided, and We only use these Cookies to provide You with those
        services.</span>
    </p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;"><strong>Cookies Policy /
          Notice Acceptance Cookies</strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Type: Persistent
        Cookies</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Administered by: Us</span>
    </p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Purpose: These Cookies
        identify if users have accepted the use of cookies on the Website.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;"><strong>Functionality
          Cookies</strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Type: Persistent
        Cookies</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Administered by: Us</span>
    </p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Purpose: These Cookies allow
        us to remember choices You make when You use the Website, such as remembering your login details or language
        preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You
        having
        to re-enter your preferences every time You use the Website.</span></p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Use
          of Your
          Personal Data</span></span></h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The Company may use Personal
        Data for the following purposes:</span></p>
    <ul>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>To provide and maintain our Service</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;">, including to monitor the
              usage
              of our Service.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>To manage Your Account:</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> to manage Your registration as
              a
              user of the Service. The Personal Data You provide can give You access to different functionalities of the
              Service that are available to You as a registered user.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>For the performance of a contract:</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> the development, compliance
              and
              undertaking of the purchase contract for the products, items or services You have purchased or of any
              other
              contract with Us through the Service.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>To contact You:</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile
              application's
              push notifications regarding updates or informative communications related to the functionalities,
              products
              or
              contracted services, including the security updates, when necessary or reasonable for their
              implementation.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>To provide You</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> with news, special offers and
              general information about other goods, services and events which we offer that are similar to those that
              you
              have already purchased or enquired about unless You have opted not to receive such
              information.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>To manage Your requests:</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> To attend and manage Your
              requests to us.</span></span></span></li>
    </ul>
    <p><span style="color: #757575;">&nbsp;</span><span style="color: #757575;">&nbsp;</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We may share your personal
        information in the following situations:</span></p>
    <ul>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>With Service Providers:</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> We may share Your personal
              information with Service Providers to monitor and analyze the use of our Service, to contact
              You.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>For Business transfers:</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> We may share or transfer Your
              personal information in connection with, or during negotiations of, any merger, sale of Company assets,
              financing, or acquisition of all or a portion of our business to another company.</span></span></span>
      </li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>With Affiliates:</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> We may share Your information
              with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy.
              Affiliates
              include Our parent company and any other subsidiaries, joint venture partners or other companies that We
              control or that are under common control with Us.</span></span></span></li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>With Business partners:</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> We may share Your information
              with Our business partners to offer You certain products, services or promotions.</span></span></span>
      </li>
      <li><span style="color: #757575;"><span style="font-family: 'Poppins', serif;"><span
              style="font-size: 13px;"><strong>With other users:</strong></span></span><span
            style="font-family: 'Poppins', serif;"><span style="font-size: 13px;"> when You share personal
              information or otherwise interact in the public areas with other users, such information may be viewed by
              all
              users and may be publicly distributed outside. If You interact with other users or register through a
              Third-Party Social Media Service, your contacts on the Third-Party Social Media Service may see Your name,
              profile, pictures and description of Your activity. Similarly, other users will be able to view
              descriptions
              of Your activity, communicate with You and view Your profile.</span></span></span></li>
    </ul>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Retention of
          Your Personal Data</span></span></h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The Company will retain Your
        Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain
        and
        use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are
        required
        to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and
        policies.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The Company will also retain
        Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the functionality of Our Service, or We are
        legally
        obligated to retain this data for longer time periods.</span></p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Transfer of
          Your Personal Data</span></span></h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Your information, including
        Personal Data, is processed at the Company's operating offices and in any other places where the parties
        involved
        in
        the processing are located. It means that this information may be transferred to &mdash; and maintained on
        &mdash;
        computers located outside of Your state, province, country or other governmental jurisdiction where the data
        protection laws may differ than those from Your jurisdiction.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Your consent to this Privacy
        Policy followed by Your submission of such information represents Your agreement to that transfer.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The Company will take all
        steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy
        Policy
        and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate
        controls in place including the security of Your data and other personal information.</span></p>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Disclosure of
          Your Personal Data</span></span></h2>
    <h3 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Business
          Transactions</span></span></h3>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">If the Company is involved
        in
        a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy Policy.</span></p>
    <h3 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Law
          enforcement</span></span></h3>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Under certain circumstances,
        the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).</span></p>
    <h3 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Other legal
          requirements</span></span></h3>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The Company may disclose
        Your
        Personal Data in the good faith belief that such action is necessary to:</span></p>
    <ul>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Comply with a legal
          obligation</span></li>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Protect and defend the
          rights or property of the Company</span></li>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Prevent or investigate
          possible wrongdoing in connection with the Service</span></li>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Protect the personal
          safety
          of Users of the Service or the public</span></li>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Protect against legal
          liability</span></li>
    </ul>
    <h2 class="western"><span style="font-family: 'Poppins', serif;"><span
          style="font-size: 14px; font-weight: 600;">Security of
          Your Personal Data</span></span></h2>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">The security of Your
        Personal
        Data is important to Us but remember that no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, we
        cannot guarantee its absolute security.</span></p>
    <p><span style="font-size: 16px; color: #000000;"><strong><span style="font-family: Calibri, serif;"><span
              style="font-family: 'Poppins', serif;">Links to Other Websites</span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">Our Service may contain
        links
        to other websites that are not operated by Us. If You click on a third-party link, you will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We have no control over and
        assume no responsibility for the content, privacy policies or practices of any third-party sites or
        services.</span>
    </p>
    <p><span style="font-size: 16px; color: #000000;"><strong><span style="font-family: Calibri, serif;"><span
              style="font-family: 'Poppins', serif;">Changes to this Privacy Policy</span></span></strong></span>
    </p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">We may update our Privacy
        Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this
        page.</span>
    </p>
    <p><span style="font-size: 16px;"><strong><span style="color: #000000;"><span
              style="font-family: Calibri, serif;"><span style="font-family: 'Poppins', serif;">Contact
                Us</span></span></span></strong></span></p>
    <p><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">If you have any questions
        about this Privacy Policy, you can contact us:</span></p>
    <ul>
      <li><span style="font-family: 'Poppins', serif; color: #757575; font-size: 13px;">By email: <span
            style="color: #78BE3C;"><a href="mailto:pls1@aiu.edu.my">pls1@aiu.edu.my</a></span></span></li>
    </ul>
  </div>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button (click)="goToTop()">
      <img src="../../../assets//images//icon-backtop.svg" />
    </ion-fab-button>
  </ion-fab>
</ion-content>