import * as i0 from "@angular/core";
export class NavExtrasService {
    constructor() { }
    setRegisterFlag() {
        this.registerFlag = true;
    }
    resetRegisterFlag() {
        this.registerFlag = false;
    }
    getRegisterFlag() {
        return this.registerFlag;
    }
    setExtras(data) {
        this.extras = data;
    }
    getExtras() {
        return this.extras;
    }
    setTicketExtra(data) {
        this.ticketExtra = data;
        return;
    }
    getTicketExtra() {
        return this.ticketExtra;
    }
    setConfirmExtra(data) {
        this.confirmExtra = data;
    }
    getConfirmExtra() {
        return this.confirmExtra;
    }
    setEventIdExtra(data) {
        this.eventIdExtra = data;
    }
    getEventIdExtra() {
        return this.eventIdExtra;
    }
    setRecentChatExtra(backRoute) {
        this.recentChatExtra = backRoute;
    }
    getRecentChatExtra() {
        return this.recentChatExtra;
    }
}
NavExtrasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavExtrasService_Factory() { return new NavExtrasService(); }, token: NavExtrasService, providedIn: "root" });
