import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
const TICKET_COLLECTION_NAME = environment.dev ? 'tickets-dev' : 'tickets';
export class TicketService {
    constructor(afs) {
        this.afs = afs;
        this.tickets = new Array();
        this.ticketsCollection = this.afs.collection(TICKET_COLLECTION_NAME, x => x.orderBy('name', 'asc'));
        this.ticketsCollection.snapshotChanges().subscribe(data => {
            this.tickets = data.map(e => {
                return Object.assign({ id: e.payload.doc.id }, e.payload.doc.data());
            });
        });
    }
    getTicket() {
        // return this.tickets;
        return this.afs.collection(TICKET_COLLECTION_NAME).snapshotChanges();
    }
    getEventTickets(eventId) {
        return this.afs.collection(TICKET_COLLECTION_NAME, ref => {
            return ref.where('event.id', '==', eventId);
        }).snapshotChanges();
    }
    addTicket(ticket) {
        this.ticketsCollection.add(ticket);
    }
    updateTicket(ticket) {
        delete ticket.event.id;
        this.afs.doc(TICKET_COLLECTION_NAME + '/' + ticket.event.id).update(ticket);
    }
    deleteTicket(ticket) {
        this.ticketDoc = this.afs.doc('tickets/${ticket.id}');
        return this.ticketDoc.delete();
    }
}
TicketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketService_Factory() { return new TicketService(i0.ɵɵinject(i1.AngularFirestore)); }, token: TicketService, providedIn: "root" });
