import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationService } from './services/authentication.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import * as firebase from 'firebase';
import { IonicStorageModule } from '@ionic/storage';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HeaderComponentModule } from './components/header/header.module';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TicketDetailsComponent } from './components/ticket-details/ticket-details.component';
import { SucceedTicketModalComponent } from './components/succeed-ticket-modal/succeed-ticket-modal.component';
import { FooterComponentModule } from './components/footer/footer.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

import {
  MatInputModule,
  MatNativeDateModule,
  MatDatepickerModule
} from '@angular/material';
import { VenueService } from './services/venue.service';
import { CountdownModule } from 'ngx-countdown';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { AddTicketFormComponentModule } from './components/add-ticket-form/add-ticket-form.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { SendCertSuccessComponent } from './components/send-cert-success/send-cert-success.component';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { TermServicesComponent } from './components/term-services/term-services.component';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { CheckInValidateComponent } from './components/check-in-validate/check-in-validate.component';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Crop } from '@ionic-native/crop/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';

firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent,
    TicketDetailsComponent,
    SucceedTicketModalComponent,
    SendCertSuccessComponent,
    TermServicesComponent,
    PrivacyComponent,
    CheckInValidateComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AngularFireAuthModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HeaderComponentModule,
    AddTicketFormComponentModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    BrowserAnimationsModule,
    FooterComponentModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    CountdownModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    AngularFireStorageModule,
    MatSortModule,
    BrowserModule.withServerTransition({ appId: 'angular-starter' }),
    ImageCropperModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AppComponent,
    TicketDetailsComponent,
    SucceedTicketModalComponent,
    SendCertSuccessComponent,
    TermServicesComponent,
    PrivacyComponent,
    CheckInValidateComponent
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthenticationService,
    FCM,
    LocalNotifications,
    VenueService,
    SocialSharing,
    Clipboard,
    Camera,
    Base64,
    CallNumber,
    InAppBrowser,
    Keyboard,
    Deeplinks,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Crop,
    File,
    FirebaseDynamicLinks
  ],
})
export class AppModule { }
