<!-- <ion-slides [options]="slidesOption">
  <ion-slide *ngFor="let ticket of ticketInformationList; let i = index"> -->
<div>
  <div height>
    <ion-row paddingTop>
      <ion-col size="2"></ion-col>
      <ion-col size="8" flexCenter noMargin>
        <ion-avatar>
          <img src="../../../assets/images/icon-mark.svg" />
        </ion-avatar>
      </ion-col>
      <ion-col flexTopRight size="2">
        <ion-text (click)="dismiss()" red>Close</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col flexCenter noMargin marginTop>
        <ion-text secondary3>
          Thank you for registering!
        </ion-text>
      </ion-col>
    </ion-row>
    <!-- <ion-row>
          <ion-col flexCenter noMargin>
            <ion-text grey paddingBottom>Order #{{ ticketOrderList[i] }}</ion-text>
          </ion-col>
        </ion-row> -->
  </div>

  <div center eventDetails>
    <ion-text secondary4>
      See you at the event!
    </ion-text>
    <p>
      <ion-text eventName>
        {{ ticketDetails.title }}
      </ion-text>
    </p>
  </div>

  <div center emailTicket>
    <ion-row>
      <ion-col size="2.5"></ion-col>
      <ion-col size="7">
        <ion-text secondary4>
          You will also receive the ticket in the registered email
        </ion-text>
      </ion-col>
      <ion-col size="2.5"></ion-col>
    </ion-row>
  </div>

  <ion-row>
    <ion-col flexCenter padding>
      <ion-text green (click)="goToTicket()">
        <b>
          View Ticket
        </b>
      </ion-text>
    </ion-col>
  </ion-row>