import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-check-in-validate',
  templateUrl: './check-in-validate.component.html',
  styleUrls: ['./check-in-validate.component.scss'],
})
export class CheckInValidateComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  close() {
    this.modalCtrl.dismiss();
  }
}
