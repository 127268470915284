<div>
  <ion-row>
    <ion-col flexRight>
      <ion-icon name="close" (click)="close()"></ion-icon>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col flexCenter>
      <ion-label>
        <b>You Are Not Checked In</b>
      </ion-label>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col flexCenter>
      <ion-label message>
        Please get your ticket validated to use this function.
      </ion-label>
    </ion-col>
  </ion-row>
</div>