import { Injectable } from '@angular/core';
import { EventDetails } from '../modal/event-model';

@Injectable({
  providedIn: 'root'
})
export class NavExtrasService {

  extras: NavigationExtraParams;
  ticketExtra: TicketDetails;
  confirmExtra: ConfirmDetails;
  eventIdExtra: EventId;
  registerFlag: boolean;
  recentChatExtra: string;

  constructor() { }

  setRegisterFlag() {
    this.registerFlag = true;
  }

  resetRegisterFlag() {
    this.registerFlag = false;
  }

  getRegisterFlag() {
    return this.registerFlag;
  }

  public setExtras(data: NavigationExtraParams) {
    this.extras = data;
  }

  public getExtras() {
    return this.extras;
  }

  public setTicketExtra(data: TicketDetails) {
    this.ticketExtra = data;
    return;
  }

  public getTicketExtra() {
    return this.ticketExtra;
  }

  public setConfirmExtra(data: ConfirmDetails) {
    this.confirmExtra = data;
  }

  public getConfirmExtra() {
    return this.confirmExtra;
  }

  public setEventIdExtra(data: EventId) {
    this.eventIdExtra = data;
  }

  public getEventIdExtra() {
    return this.eventIdExtra;
  }

  public setRecentChatExtra(backRoute: string) {
    this.recentChatExtra = backRoute;
  }

  public getRecentChatExtra() {
    return this.recentChatExtra;
  }
}

export interface NavigationExtraParams {
  backRoute: string;
  loginRoute?: string;
}

export interface TicketDetails {
  title: string;
  date: any;
  author: string;
  seat: string;
  end: any;
  location: string;
  eventId: string;
  minTicket: number;
  maxTicket: number;
}

export interface ConfirmDetails {
  order_no: string;
  email: string;
}

export interface EventId {
  id: string;
}
