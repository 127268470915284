import * as tslib_1 from "tslib";
import * as firebase from 'firebase/app';
import { NavController } from '@ionic/angular';
import { NavExtrasService } from './nav-extras.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "./nav-extras.service";
import * as i3 from "@angular/common/http";
const tableName = '102rYXYzi-iPMHlxMoFFKwgAB7XHDF1vTNssUm828gn0';
export class FirebaseService {
    constructor(navCtrl, navExtra, httpClient) {
        this.navCtrl = navCtrl;
        this.navExtra = navExtra;
        this.httpClient = httpClient;
    }
    signOut(loginBackRoutes, loginRoutes) {
        firebase.auth().signOut().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.navExtra.setExtras({ backRoute: loginBackRoutes, loginRoute: loginRoutes });
            this.navCtrl.navigateRoot('login');
        }));
    }
    getCurrentUser() {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
            return currentUser;
        }
        else {
            return null;
        }
    }
    getFirebaseRef(address) {
        return firebase.database().ref(`${tableName}/${address}`);
    }
    firebaseOnAuth() {
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(user => {
                try {
                    if (user) {
                        resolve(user);
                    }
                    else {
                        resolve(null);
                    }
                }
                catch (error) {
                    console.error('firebaseOnAuth error:', error);
                    reject(error);
                }
            });
        });
    }
    resetPassword(email) {
        return firebase.auth().sendPasswordResetEmail(email);
    }
    registerUser(requestBody) {
        return new Promise((resolve, reject) => {
            try {
                const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/createUser';
                const header = new HttpHeaders().set('Content-Type', 'application/json');
                const option = {
                    headers: header
                };
                this.httpClient.post(URL, requestBody, option).subscribe(res => {
                    resolve(res);
                }, error => {
                    console.error('registerUser error:', error);
                    reject(error);
                });
            }
            catch (error) {
                console.error('registerUser error:', error);
                reject(error);
            }
        });
    }
    sendTicketDetailsToEmail(requestBody) {
        console.log('requestBody', requestBody);
        return new Promise((resolve, reject) => {
            const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/sendPurchaseComfirmationEmail';
            const header = new HttpHeaders().set('Content-Type', 'application/json');
            const option = {
                headers: header
            };
            this.httpClient.post(URL, requestBody, option).subscribe(res => {
                resolve(res);
            }, error => {
                console.error('sendTicketDetailsToEmail error:', error);
                reject(error);
            });
        });
    }
    sendCertificateToEmail(requestBody) {
        return new Promise((resolve, reject) => {
            const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/sendUserCertificate';
            const header = new HttpHeaders().set('Content-Type', 'application/json');
            const option = {
                headers: header
            };
            this.httpClient.post(URL, requestBody, option).subscribe(res => {
                resolve(res);
            }, error => {
                console.error('sendCertificateToEmail error:', error);
                reject(error);
            });
        });
    }
    updateUser(requestBody) {
        return new Promise((resolve, reject) => {
            const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/updateUser';
            const header = new HttpHeaders().set('Content-Type', 'application/json');
            const option = {
                headers: header
            };
            this.httpClient.post(URL, requestBody, option).subscribe(res => {
                resolve(res);
            }, error => {
                console.error('updateUser error:', error);
                reject(error);
            });
        });
    }
    subscribeToToken(requestBody) {
        return new Promise((resolve, reject) => {
            const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/subscribeTopic';
            const header = new HttpHeaders().set('Content-Type', 'application/json');
            const option = {
                headers: header
            };
            this.httpClient.post(URL, requestBody, option).subscribe(res => {
                console.log('subscribeToToken response:', res);
                resolve(res);
            }, error => {
                console.error(error);
                resolve(error);
            });
        });
    }
}
FirebaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirebaseService_Factory() { return new FirebaseService(i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i2.NavExtrasService), i0.ɵɵinject(i3.HttpClient)); }, token: FirebaseService, providedIn: "root" });
