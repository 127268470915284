import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { NavExtrasService } from './nav-extras.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private navCtrl: NavController,
    private storage: Storage,
    private navExtra: NavExtrasService
  ) { }

  async canActivate() {
    const userId: string = await this.storage.get('userId');

    if (!userId) {
      this.navExtra.setExtras(this.navExtra.getExtras());
      this.navCtrl.navigateRoot('login');
    }
    if (userId) {
      return true;
    }
  }
}
