import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-send-cert-success',
  templateUrl: './send-cert-success.component.html',
  styleUrls: ['./send-cert-success.component.scss'],
})
export class SendCertSuccessComponent implements OnInit {

  valid: boolean = false;
  email: string = '';
  certList: any;

  constructor(
    private modalCtrl: ModalController,
    private firebaseService: FirebaseService
  ) { }

  async ngOnInit() {
    console.log('success email', this.email);
    console.log('success certlist', this.certList);

    const requestBody = {
      email: this.email,
      eventName: this.certList.eventName,
      certLink: this.certList.certLink
    };

    console.log('requestBody', requestBody);
    const response = await this.firebaseService.sendCertificateToEmail(requestBody);
    console.log('response:', response);
  }

  dismiss() {
    this.modalCtrl.dismiss().then(() => {
      this.modalCtrl.dismiss();
    });
  }

  goToProfile() {
    this.modalCtrl.dismiss();
  }
}
