/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout-popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./logout-popover.component";
import * as i5 from "../../services/firebase.service";
import * as i6 from "@ionic/storage";
var styles_LogoutPopoverComponent = [i0.styles];
var RenderType_LogoutPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutPopoverComponent, data: {} });
export { RenderType_LogoutPopoverComponent as RenderType_LogoutPopoverComponent };
export function View_LogoutPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-row", [["logoutPop", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-label", [["green", ""]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Log out"])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "ion-icon", [["green", ""], ["name", "exit"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(6, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "exit"; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_LogoutPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout-popover", [], null, null, null, View_LogoutPopoverComponent_0, RenderType_LogoutPopoverComponent)), i1.ɵdid(1, 114688, null, 0, i4.LogoutPopoverComponent, [i5.FirebaseService, i3.PopoverController, i6.Storage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutPopoverComponentNgFactory = i1.ɵccf("app-logout-popover", i4.LogoutPopoverComponent, View_LogoutPopoverComponent_Host_0, {}, {}, []);
export { LogoutPopoverComponentNgFactory as LogoutPopoverComponentNgFactory };
