import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { SucceedTicketModalComponent } from '../succeed-ticket-modal/succeed-ticket-modal.component';
import { TicketService } from '../../services/ticket.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavExtrasService } from 'src/app/services/nav-extras.service';
// import { uuid } from 'uuidv4';
import * as uuid from 'uuid';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoadingService } from 'src/app/services/loading-service.service';
import { TermServicesComponent } from '../../components/term-services/term-services.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
export class TicketDetailsComponent {
    constructor(modalCtrl, ticketService, afs, alertCtrl, navExtraService, firebaseService, router, loadingService, inAppBrowser) {
        this.modalCtrl = modalCtrl;
        this.ticketService = ticketService;
        this.afs = afs;
        this.alertCtrl = alertCtrl;
        this.navExtraService = navExtraService;
        this.firebaseService = firebaseService;
        this.router = router;
        this.loadingService = loadingService;
        this.inAppBrowser = inAppBrowser;
        this.quantities = [];
        this.fullname = [''];
        this.email = [''];
        this.phoneNumber = [''];
        this.checkmark = [];
        this.expand = [];
        this.valid = false;
        this.newsletter = [];
        this.campusTour = [];
        this.contactName = '';
        this.contactEmail = '';
        this.tnc = false;
        this.campus = false;
        this.currentTimeInMilliseconds = Date.now();
        this.uid = [''];
    }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.firebaseService.firebaseOnAuth();
            if (user) {
                this.contactName = user.displayName;
                this.contactEmail = user.email;
                this.fullname[0] = user.displayName;
                this.email[0] = user.email;
                this.phoneNumber[0] = user.phoneNumber;
                this.expand[0] = true;
                this.uid[0] = user.uid;
            }
        });
    }
    ngOnInit() {
        for (let i = 0; i < this.ticketQuantity; i++) {
            this.quantities.push(1);
            this.newsletter.push(false);
            this.campusTour.push(false);
            this.checkmark.push(false);
            this.expand.push(false);
        }
    }
    dismiss() {
        this.modalCtrl.dismiss().then(() => {
            this.modalCtrl.dismiss();
        });
    }
    back() {
        this.modalCtrl.dismiss();
    }
    submit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log('full', this.fullname);
            const ticketList = [];
            const ticket = this.navExtraService.getTicketExtra();
            for (let i = 0; i < this.ticketQuantity; i++) {
                const uniqueId = uuid.v1();
                const ticketDetails = {
                    created_at: '',
                    email: this.email[i],
                    event: {
                        author: ticket.author,
                        endDate: ticket.end,
                        id: ticket.eventId,
                        startDate: ticket.date,
                        title: ticket.title,
                        venue: ticket.location
                    },
                    name: this.fullname[i],
                    order_no: '',
                    phone_no: this.phoneNumber[i],
                    purchased_by: this.contactEmail,
                    qr_code: uniqueId,
                    ticket_url: environment.dev ?
                        `https://dev-pls-aiu.web.app/ticket/${ticket.eventId}/${uniqueId}` : `https://pls.aiu.edu.my/ticket/${ticket.eventId}/${uniqueId}`,
                    campus_tour: this.campusTour[i],
                    newsletter: this.newsletter[i],
                    bcc: '',
                    id: '',
                    seats: ticket.seat,
                    uid: '',
                    dateCreated: new Date()
                };
                ticketList.push(ticketDetails);
            }
            const valid = this.validationChecking(ticketList);
            // console.log('ticketList', ticketList);
            if (valid) {
                this.loadingService.present();
                const remainingTicketQuantity = yield this.checkTicketQuantity();
                const duplicateCheck = yield this.checkDuplicateEmail();
                const ticketSufficient = this.ticketQuantity <= remainingTicketQuantity ? true : false;
                if (ticketSufficient) {
                    if (!duplicateCheck) {
                        try {
                            for (const index in ticketList) {
                                console.log('Does not exist.');
                                this.ticketService.addTicket(ticketList[index]);
                                if (index === (ticketList.length - 1).toString()) {
                                    this.modalCtrl.dismiss().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                        this.modalCtrl.dismiss().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                            const modal = this.modalCtrl.create({
                                                component: SucceedTicketModalComponent,
                                                cssClass: 'ticket-succeed',
                                                componentProps: {
                                                    ticketInformationList: ticketList,
                                                    contactName: this.contactName,
                                                    contactEmail: this.contactEmail,
                                                    eventId: ticket.eventId
                                                }
                                            });
                                            this.loadingService.dismiss();
                                            (yield modal).present();
                                        }));
                                    }));
                                }
                            }
                        }
                        catch (error) {
                            console.error('ticketSubmit error:', error);
                            this.loadingService.dismiss();
                        }
                    }
                    else {
                        this.loadingService.dismiss();
                        const alert = this.alertCtrl.create({
                            message: `Duplicate email with (${this.email})! Please use another email address.`,
                            buttons: ['OK']
                        });
                        (yield alert).present();
                    }
                }
                else {
                    this.loadingService.dismiss();
                    const alert = this.alertCtrl.create({
                        message: remainingTicketQuantity === 0 ? 'Sorry, the tickets are sold out.'
                            : `Sorry, only ${remainingTicketQuantity} seat(s) left.`,
                        buttons: ['Ok']
                    });
                    (yield alert).present();
                }
            }
            else {
                const alert = this.alertCtrl.create({
                    message: 'Please enter valid input.',
                    buttons: ['OK']
                });
                (yield alert).present();
            }
        });
    }
    timesUp(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (event.action === 'done') {
                const alert = this.alertCtrl.create({
                    header: 'Message',
                    message: 'Time limit reached.',
                    buttons: ['OK']
                });
                (yield alert).present();
                this.modalCtrl.dismiss();
            }
        });
    }
    validationChecking(ticketArray) {
        const regex = new RegExp('[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})');
        let regexCheck = false;
        let duplicate = 0;
        let required = 0;
        let emailChecker = '';
        let fullnameChecker = '';
        let phoneNumberChecker = '';
        for (const ticket of ticketArray) {
            if (ticket.email === emailChecker
                || ticket.name === fullnameChecker
                || ticket.phone_no === phoneNumberChecker) {
                duplicate++;
            }
            if (ticket.email === '' || ticket.name === '' || ticket.phone_no === '') {
                required++;
            }
            regexCheck = regex.test(ticket.email);
            emailChecker = ticket.email;
            fullnameChecker = ticket.name;
            phoneNumberChecker = ticket.phone_no;
        }
        // console.log('dup', duplicate, 'required', required, 'regex', regexCheck);
        return (duplicate === 0 && required === 0 && regexCheck);
    }
    promptConfirmation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const actionSheet = this.alertCtrl.create({
                header: 'Confirmation',
                message: ' Are you sure the information is correct?',
                buttons: [
                    {
                        text: 'Cancel',
                        handler: () => {
                            this.alertCtrl.dismiss();
                        }
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            this.submit();
                        }
                    }
                ]
            });
            (yield actionSheet).present();
        });
    }
    checkInput() {
        let validCounter = 0;
        if (this.contactEmail && this.contactName) {
            for (let i = 0; i < this.ticketQuantity; i++) {
                if (this.fullname[i] && this.email[i] && this.phoneNumber[i]) {
                    validCounter++;
                    this.checkmark[i] = true;
                }
                else {
                    this.checkmark[i] = false;
                }
            }
            if (validCounter.toString() === this.ticketQuantity.toString()) {
                this.valid = true;
            }
            else {
                this.valid = false;
            }
        }
        else {
            this.valid = false;
        }
    }
    checkInputName(ev, index) {
        const name = ev.detail.value;
        if (name !== '') {
            const capitalizeName = name.split(' ').map(word => {
                return word.replace(word[0], word[0].toUpperCase());
            }).join(' ');
            this.fullname[index] = capitalizeName;
        }
        let validCounter = 0;
        if (this.contactEmail && this.contactName) {
            for (let i = 0; i < this.ticketQuantity; i++) {
                if (this.fullname[i] && this.email[i] && this.phoneNumber[i]) {
                    validCounter++;
                    this.checkmark[i] = true;
                }
                else {
                    this.checkmark[i] = false;
                }
            }
            if (validCounter.toString() === this.ticketQuantity.toString()) {
                this.valid = true;
            }
            else {
                this.valid = false;
            }
        }
        else {
            this.valid = false;
        }
    }
    checkDuplicateEmail() {
        const ticketCollection = environment.dev ? 'tickets-dev' : 'tickets';
        const ticket = this.navExtraService.getTicketExtra();
        return new Promise((resolve, reject) => {
            try {
                this.afs.collection(ticketCollection, ref => ref.where('event.id', '==', ticket.eventId)
                    .where('email', 'in', this.email)).get().subscribe(res => {
                    if (!res.empty) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            catch (error) {
                console.error('checkDuplicateEmail error:', error);
                reject(error);
            }
        });
    }
    expands(index) {
        if (this.expand[index] == true) {
            this.expand[index] = !this.expand[index];
        }
        else {
            for (let i = 0; i < this.expand.length; i++) {
                this.expand[i] = false;
            }
            this.expand[index] = true;
        }
    }
    checkTnc() {
        this.tnc = !this.tnc;
        for (let i = 0; i < this.ticketQuantity; i++) {
            this.newsletter[i] = !this.newsletter[i];
        }
    }
    checkCampus() {
        this.campus = !this.campus;
        for (let i = 0; i < this.ticketQuantity; i++) {
            this.campusTour[i] = !this.campusTour[i];
        }
    }
    goToTerms() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = this.modalCtrl.create({
                component: TermServicesComponent,
            });
            (yield modal).present();
        });
    }
    goToPrivacy() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = this.modalCtrl.create({
                component: PrivacyComponent
            });
            (yield modal).present();
        });
    }
    checkTicketQuantity() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let ticketQty;
            const ticket = this.navExtraService.getTicketExtra();
            const eventCollection = environment.dev ? 'events-dev' : 'events';
            const checkTicketQuantity = yield this.afs.collection(eventCollection, query => query.where('id', '==', ticket.eventId)).get().toPromise().then(data => {
                if (!data.empty) {
                    data.forEach((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        ticketQty = res.data().tickets.availTicket;
                    }));
                }
            });
            return ticketQty;
        });
    }
    openCampusTour() {
        window.open('https://firebasestorage.googleapis.com/v0/b/aiu-event-management.appspot.com/o/campus%20tour%2FMini%20Campus%20Tour%20-%20Walking%20Trail%20v1%20(17%20Feb%202020).pdf?alt=media&token=e9491c22-d4fc-4d6e-bfda-cda55b605f32', '_system', 'location=yes');
    }
}
