import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { TicketModal } from '../modal/ticket-modal';
import { environment } from '../../environments/environment';

const TICKET_COLLECTION_NAME = environment.dev ? 'tickets-dev' : 'tickets';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  ticketsCollection: AngularFirestoreCollection<TicketModal>;
  tickets: Array<TicketModal> = new Array();
  ticketDoc: AngularFirestoreDocument<TicketModal>;

  constructor(public afs: AngularFirestore) {
    this.ticketsCollection = this.afs.collection(TICKET_COLLECTION_NAME, x => x.orderBy('name', 'asc'));
    this.ticketsCollection.snapshotChanges().subscribe(
      data => {
        this.tickets = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data()
          } as TicketModal;
        });
      });
  }

  getTicket() {
    // return this.tickets;
    return this.afs.collection(TICKET_COLLECTION_NAME).snapshotChanges();
  }

  getEventTickets(eventId: string) {
    return this.afs.collection(TICKET_COLLECTION_NAME, ref => {
      return ref.where('event.id', '==', eventId);
    }
    ).snapshotChanges();
  }

  addTicket(ticket: TicketModal) {
    this.ticketsCollection.add(ticket);
  }

  updateTicket(ticket: TicketModal) {
    delete ticket.event.id;
    this.afs.doc(TICKET_COLLECTION_NAME + '/' + ticket.event.id).update(ticket);
  }

  deleteTicket(ticket: TicketModal) {
    this.ticketDoc = this.afs.doc('tickets/${ticket.id}');
    return this.ticketDoc.delete();
  }
}
