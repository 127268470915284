import { Component, OnInit } from '@angular/core';
import { NavExtrasService, TicketDetails, ConfirmDetails } from 'src/app/services/nav-extras.service';
import { ModalController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { TicketModal, TicketBody } from '../../modal/ticket-modal';
import { Router, NavigationExtras } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-succeed-ticket-modal',
  templateUrl: './succeed-ticket-modal.component.html',
  styleUrls: ['./succeed-ticket-modal.component.scss'],
})
export class SucceedTicketModalComponent implements OnInit {

  slidesOption = {
    slidesPerView: 1,
    centeredSlides: true
  };

  ticketDetails: TicketDetails;
  ticketOrderList: string[] = [];
  ticketInformationList: Array<TicketModal>;
  confirmDetails: ConfirmDetails;
  tickets: Array<TicketModal> = new Array();
  ticketId: string;
  qrCode: string;
  eventId: string;
  contactName: string;
  contactEmail: string;
  ticketBodyArray: Array<TicketBody> = [];

  constructor(
    private navExtraService: NavExtrasService,
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private router: Router,
    private firebaseService: FirebaseService
  ) {
    this.getTicketDetails();
    this.getConfirmDetails();
  }

  ngOnInit() {
  }

  async ionViewWillEnter() {
    this.ticketOrderList = [];
    const ticketCollection = environment.dev ? 'tickets-dev' : 'tickets';
    const ticketBodyArray: Array<TicketBody> = [];
    for (const ticket of this.ticketInformationList) {
      const promise = await this.afs.collection(ticketCollection, ref => ref.where('email', '==', ticket.email)
        .where('event.id', '==', this.eventId)).get().forEach(data => {
          data.forEach(res => {
            this.ticketOrderList.push(res.id);
            ticket['orderNumber'] = res.id;

            const ticketBody: TicketBody = {
              email: ticket.email,
              name: ticket.name,
              orderId: ticket.orderNumber,
            };

            ticketBodyArray.push(ticketBody);
          });
        });
    }
    console.log('ticketBody', ticketBodyArray);

    const requestBody = {
      event_name: this.ticketDetails.title,
      contact: {
        name: this.contactName,
        email: this.contactEmail
      },
      tickets: ticketBodyArray
    };

    console.log('requestBody', requestBody);
    const response = await this.firebaseService.sendTicketDetailsToEmail(requestBody);
    console.log('response:', response);
  }

  async getTicketDetails() {
    this.ticketDetails = await this.navExtraService.getTicketExtra();
  }

  async getConfirmDetails() {
    this.confirmDetails = await this.navExtraService.getConfirmExtra();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  goToTicket() {
    const navExtra: NavigationExtras = {
      state: {
        ticketDetailsList: this.ticketInformationList
      }
    };

    this.router.navigateByUrl(`ticket-information`, navExtra);
    this.modalCtrl.dismiss();
  }
}
