import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'event-home', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'event-details/:eventId',
    loadChildren: () => import('./pages/event-details/event-details.module').then(m => m.EventDetailsPageModule)
  },
  {
    path: 'chat-room/:eventId/:targetUserId',
    loadChildren: () => import('./pages/chat-room/chat-room.module').then(m => m.ChatRoomPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'attendee/:eventId',
    loadChildren: () => import('./pages/attendee/attendee.module').then(m => m.AttendeePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'event-home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'recent-chat/:eventId',
    loadChildren: () => import('./pages/recent-chat/recent-chat.module').then(m => m.RecentChatPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'booth',
    loadChildren: () => import('./pages/booth/booth.module').then(m => m.BoothPageModule)
  },
  {
    path: 'polls/:eventId',
    loadChildren: () => import('./pages/polls/polls.module').then(m => m.PollsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'campus-tour',
    loadChildren: () => import('./pages/campus-tour/campus-tour.module').then(m => m.CampusTourPageModule)
  },
  {
    path: 'download/:eventId',
    loadChildren: () => import('./pages/download/download.module').then(m => m.DownloadPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'admin/events',
    loadChildren: () => import('./pages/event-listing/event-listing.module').then(m => m.EventListingPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'create-event',
    loadChildren: () => import('./pages/create-event/create-event.module').then(m => m.CreateEventPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin/event/:eventId',
    loadChildren: () => import('./pages/edit-event/edit-event.module').then(m => m.EditEventPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin/venues',
    loadChildren: () => import('./pages/venue/venue.module').then(m => m.VenuePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin/venue/:venueId',
    loadChildren: () => import('./pages/edit-venue/edit-venue.module').then(m => m.EditVenuePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'create-venue',
    loadChildren: () => import('./pages/create-venue/create-venue.module').then(m => m.CreateVenuePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'ticket-details',
    loadChildren: () => import('./pages/ticket-details/ticket-details.module').then(m => m.TicketDetailsPageModule)
  },
  {
    path: 'profile-ticket',
    loadChildren: () => import('./pages/profile-ticket/profile-ticket.module').then(m => m.ProfileTicketPageModule)
  },

  {
    path: 'ticket/:eventId/:qrCode',
    loadChildren: () => import('./pages/ticket-route/ticket-route.module').then(m => m.TicketRoutePageModule)
  },
  {
    path: 'ticket-information',
    loadChildren: () => import('./pages/ticket-information/ticket-information.module').then(m => m.TicketInformationPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule)
  },
  {
    path: 'admin/calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'forget-passwords',
    loadChildren: () => import('./pages/forget-passwords/forget-passwords.module').then(m => m.ForgetPasswordsPageModule)
  },
  {
    path: 'admin/view-event/:eventId',
    loadChildren: () => import('./pages/view-event/view-event.module').then(m => m.ViewEventPageModule)
  },
  {
    path: 'admin/view-venue/:venueId',
    loadChildren: () => import('./pages/view-venue/view-venue.module').then(m => m.ViewVenuePageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'admin/banners',
    loadChildren: () => import('./pages/banner-listing/banner-listing.module').then(m => m.BannerListingPageModule)
  },
  {
    path: 'admin/create-banner',
    loadChildren: () => import('./pages/create-banner/create-banner.module').then(m => m.CreateBannerPageModule)
  },
  {
    path: 'admin/edit-banner/:bannerId',
    loadChildren: () => import('./pages/edit-banner/edit-banner.module').then(m => m.EditBannerPageModule)
  },
  {
    path: 'admin/view-banner/:bannerId',
    loadChildren: () => import('./pages/view-banner/view-banner.module').then(m => m.ViewBannerPageModule)
  },
  {
    path: 'admin/push-notification',
    loadChildren: () => import('./pages/push-notification-listing/push-notification-listing.module').then(m => m.PushNotificationListingPageModule)
  },
  {
    path: 'admin/view-push-notification/:pushId',
    loadChildren: () => import('./pages/view-push-notification/view-push-notification.module').then(m => m.ViewPushNotificationPageModule)
  },
  {
    path: 'admin/create-push-notification',
    loadChildren: () => import('./pages/create-push-notification/create-push-notification.module').then(m => m.CreatePushNotificationPageModule)
  },
  {
    path: 'admin/event-report/:eventId',
    loadChildren: () => import('./pages/event-report/event-report.module').then(m => m.EventReportPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset-password-success',
    loadChildren: () => import('./pages/reset-password-success/reset-password-success.module').then(m => m.ResetPasswordSuccessPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
