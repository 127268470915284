import * as tslib_1 from "tslib";
import { EventEmitter, AfterViewInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';
import { PopoverController, NavController, Platform, ActionSheetController, ModalController } from '@ionic/angular';
import { LogoutPopoverComponent } from '../logout-popover/logout-popover.component';
import { NavExtrasService } from 'src/app/services/nav-extras.service';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
const NOTIFICATION_COLLECTION = environment.dev ? 'notifications-dev' : 'notifications';
export class HeaderComponent {
    constructor(firebaseService, router, popoverCtrl, navCtrl, navExtraService, platform, storage, actionSheet, afs, modalCtrl) {
        this.firebaseService = firebaseService;
        this.router = router;
        this.popoverCtrl = popoverCtrl;
        this.navCtrl = navCtrl;
        this.navExtraService = navExtraService;
        this.platform = platform;
        this.storage = storage;
        this.actionSheet = actionSheet;
        this.afs = afs;
        this.modalCtrl = modalCtrl;
        this.headerDetails = new EventEmitter();
        this.emptyProfilePicture = 'assets/images/empty.png';
        this.dropdownMenu = false;
        this.desktop = false;
        this.mobile = false;
        this.admin = false;
        this.showMng = false;
        this.getUser();
        this.initializeApp();
        this.getNotificationCount();
    }
    initializeApp() {
        this.platform.ready().then(() => {
            if (this.platform.is('desktop')) {
                this.desktop = true;
            }
            else if (this.platform.is('mobile')) {
                this.mobile = true;
            }
        });
    }
    ngAfterViewInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // check admin role
            yield this.getUser();
            this.afs.collection('profile', ref => ref.where('uid', '==', this.currentId))
                .snapshotChanges().subscribe(data => {
                this.profile = data.map(e => {
                    return Object.assign({ id: e.payload.doc.id }, e.payload.doc.data());
                });
                if (this.profile[0].role === 'admin') {
                    this.admin = true;
                }
            });
        });
    }
    login() {
        this.navExtraService.setExtras({ backRoute: this.loginBackRoute, loginRoute: this.loginRoute });
        this.navCtrl.navigateRoot('login');
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storage.clear();
            const user = yield this.firebaseService.firebaseOnAuth();
            const FIRESTORE = firebase.firestore();
            this.currentUserId = user.uid;
            let profileDocId = '';
            const getProfileDocId = yield this.afs.collection('profile', query => query.where('uid', '==', this.currentUserId))
                .get().toPromise().then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                // console.log('response', res);
                if (!res.empty) {
                    res.forEach(data => {
                        profileDocId = data.id;
                    });
                    const updateProfileFCM = yield FIRESTORE.collection('profile').doc(profileDocId)
                        .update({
                        deviceToken: null
                    });
                }
                else {
                    console.log('EMPTY');
                }
            }));
            console.log('loginBack', this.loginBackRoute);
            if (this.loginBackRoute === 'attendee' || 'recent-chat' || 'polls' || 'download' || !this.loginBackRoute) {
                this.loginBackRoute = 'event-home';
            }
            this.firebaseService.signOut(this.loginBackRoute, this.loginRoute);
        });
    }
    logInBack() {
        console.log('backRoute:', this.backRoute);
        this.navCtrl.navigateRoot(this.backRoute);
    }
    back() {
        console.log('back', this.backRoute);
        if (!this.termsAndPrivacy) {
            this.router.navigateByUrl(this.backRoute);
        }
        else {
            this.modalCtrl.dismiss();
        }
    }
    presentPopover(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(ev);
            const popover = this.popoverCtrl.create({
                component: LogoutPopoverComponent,
                componentProps: {
                    loginBackRoute: this.loginBackRoute,
                    loginRoute: this.loginRoute
                },
                event: ev,
            });
            (yield popover).present();
        });
    }
    showMenu() {
        this.dropdownMenu = !this.dropdownMenu;
    }
    getUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = yield this.firebaseService.firebaseOnAuth();
            if (user) {
                this.currentId = user.uid;
                this.profilePictureURL = user.photoURL ? user.photoURL : this.emptyProfilePicture;
                this.headerDetails.emit({
                    userId: this.currentId,
                    profilePicture: this.profilePictureURL,
                    username: user.displayName
                });
            }
            return user;
        });
    }
    getNotificationCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notificationCount = 0;
            const userId = (yield this.firebaseService.firebaseOnAuth()).uid;
            const notifications = yield this.afs.collection(NOTIFICATION_COLLECTION, query => query.where('userId', '==', userId));
            notifications.snapshotChanges().forEach(data => {
                this.notificationCount = 0;
                data.map(res => {
                    const notificationStatus = res.payload.doc.data().isRead;
                    // console.log('status', notificationStatus);
                    if (!notificationStatus) {
                        this.notificationCount++;
                    }
                });
            });
        });
    }
    goToProfileTicket() {
        this.router.navigateByUrl('profile-ticket');
        this.dropdownMenu = false;
    }
    goToNotifications() {
        this.router.navigateByUrl('notification');
        this.dropdownMenu = false;
    }
    goToProfile() {
        this.router.navigateByUrl('profile');
        this.dropdownMenu = false;
    }
    goToEvent() {
        this.router.navigateByUrl('admin/events');
        this.dropdownMenu = false;
    }
    goToVenue() {
        this.router.navigateByUrl('admin/venues');
        this.dropdownMenu = false;
    }
    goToBanner() {
        this.router.navigateByUrl('admin/banners');
        this.dropdownMenu = false;
    }
    goToPush() {
        this.router.navigateByUrl('admin/push-notification');
        this.dropdownMenu = false;
    }
    goToCalendar() {
        this.router.navigateByUrl('admin/calendar');
        this.dropdownMenu = false;
    }
    navigateHome() {
        this.navCtrl.navigateRoot('event-home');
        this.dropdownMenu = false;
    }
}
