import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class LoadingService {
    constructor(loadingController) {
        this.loadingController = loadingController;
        this.isLoading = false;
    }
    present(optsOrMessage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const opts = typeof optsOrMessage === 'string' ? { message: optsOrMessage } : optsOrMessage;
            this.isLoading = true;
            return yield this.loadingController.create(opts).then(htmlIonLoadingElement => {
                htmlIonLoadingElement.present().then(() => {
                    if (!this.isLoading) {
                        htmlIonLoadingElement.dismiss().then(() => {
                            console.log('abort presenting');
                        });
                    }
                });
                return htmlIonLoadingElement;
            });
        });
    }
    dismiss(hideError = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = false;
            try {
                return yield this.loadingController.dismiss().then(() => console.log('dismissed'));
            }
            catch (e) {
                if (!hideError) {
                    // console.log('dismiss loading controller:', e);
                }
                return null;
            }
        });
    }
}
LoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(i0.ɵɵinject(i1.LoadingController)); }, token: LoadingService, providedIn: "root" });
