import * as tslib_1 from "tslib";
import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Router } from '@angular/router';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
export class AppComponent {
    constructor(platform, statusBar, splashScreen, fcm, router, localNotification, toastController, firebaseDynamicLinks) {
        this.platform = platform;
        this.statusBar = statusBar;
        this.splashScreen = splashScreen;
        this.fcm = fcm;
        this.router = router;
        this.localNotification = localNotification;
        this.toastController = toastController;
        this.firebaseDynamicLinks = firebaseDynamicLinks;
        this.initializeApp();
    }
    initializeApp() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.platform.ready().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!this.platform.is('desktop')) {
                    const pushPermission = yield this.fcm.requestPushPermission();
                    console.log('Push permission is ', pushPermission);
                }
                this.firebaseDynamicLinks.onDynamicLink().subscribe((res) => {
                    console.log(res);
                    const url = res.deepLink.split("/");
                    console.log("url", url);
                    this.router.navigateByUrl('event-details/' + url[4]);
                    this.router.navigateByUrl('login');
                }, (error) => console.log(error));
                this.statusBar.styleDefault();
                this.splashScreen.hide();
                if (!this.platform.is('desktop')) {
                    this.fcm.onNotification().subscribe(data => {
                        console.log('data:', JSON.stringify(data));
                        if (data.wasTapped) {
                            const state = {
                                state: {
                                    targetId: data.targetId,
                                    targetName: data.name
                                }
                            };
                            if (data.type === 'chat') {
                                this.router.navigateByUrl(`chat-room/${data.eventId}/${data.targetId}`, state);
                            }
                        }
                        else {
                            if (this.platform.is('android')) {
                                this.localNotification.schedule({
                                    title: data.title,
                                    text: data.body,
                                    vibrate: true,
                                    foreground: true
                                });
                            }
                            else if (this.platform.is('ios')) {
                                this.presentIOSToast(data);
                            }
                        }
                    });
                }
            }));
        });
    }
    presentIOSToast(notification) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = this.toastController.create({
                message: notification.body,
                header: notification.title,
                duration: 3000,
                position: 'top',
                cssClass: 'toastMessage'
            });
            (yield toast).present();
        });
    }
}
