import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-logout-popover',
  templateUrl: './logout-popover.component.html',
  styleUrls: ['./logout-popover.component.scss'],
})
export class LogoutPopoverComponent implements OnInit {

  loginBackRoute: string;
  loginRoute: string;

  constructor(
    private firebaseService: FirebaseService,
    private popoverCtrl: PopoverController,
    private storage: Storage
  ) { }

  ngOnInit() { }

  async logout() {
    await this.storage.clear();

    console.log('loginBack', this.loginBackRoute);
    if (this.loginBackRoute === 'attendee' || 'recent-chat' || 'polls' || 'download' || !this.loginBackRoute) {
      this.loginBackRoute = 'event-home';
    }
    this.popoverCtrl.dismiss();
    this.firebaseService.signOut(this.loginBackRoute, this.loginRoute);
  }

}
