import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
export class LogoutPopoverComponent {
    constructor(firebaseService, popoverCtrl, storage) {
        this.firebaseService = firebaseService;
        this.popoverCtrl = popoverCtrl;
        this.storage = storage;
    }
    ngOnInit() { }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storage.clear();
            console.log('loginBack', this.loginBackRoute);
            if (this.loginBackRoute === 'attendee' || 'recent-chat' || 'polls' || 'download' || !this.loginBackRoute) {
                this.loginBackRoute = 'event-home';
            }
            this.popoverCtrl.dismiss();
            this.firebaseService.signOut(this.loginBackRoute, this.loginRoute);
        });
    }
}
