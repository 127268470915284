import * as tslib_1 from "tslib";
import * as firebase from 'firebase';
import { AlertController, ActionSheetController } from '@ionic/angular';
import { LoadingService } from './loading-service.service';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const CHECKINATTENDEE_COLLECTION = environment.dev ? 'checkInAttendee-dev' : 'checkInAttendee';
export class AuthenticationService {
    constructor(alertCtrl, loadingService, storage, httpClient, actionSheetCtrl) {
        this.alertCtrl = alertCtrl;
        this.loadingService = loadingService;
        this.storage = storage;
        this.httpClient = httpClient;
        this.actionSheetCtrl = actionSheetCtrl;
    }
    canActivate() {
        return firebase.auth().currentUser ? true : false;
    }
    registerUser(value) {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
                .then(res => resolve(res), err => reject(err));
        });
    }
    loginUser(value) {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(value.email, value.password)
                .then(res => {
                this.storage.set('userId', res.user.uid);
                resolve(res);
            }, (err) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                console.log(err);
                if (err.code === 'auth/invalid-email') {
                    const alert = this.alertCtrl.create({
                        message: 'Please enter a valid email address.',
                        buttons: ['OK']
                    });
                    (yield alert).present();
                    this.loadingService.dismiss();
                    reject(err);
                }
                else if (err.code === 'auth/user-not-found') {
                    this.findUserByEmail(value.email);
                    this.loadingService.dismiss();
                    reject(err);
                }
                else {
                    const alert = this.alertCtrl.create({
                        message: 'You have entered invalid email or password.',
                        buttons: ['OK']
                    });
                    (yield alert).present();
                    this.loadingService.dismiss();
                    reject(err);
                }
            }));
        });
    }
    logoutUser() {
        return new Promise((resolve, reject) => {
            if (firebase.auth().currentUser) {
                firebase.firestore().collection(CHECKINATTENDEE_COLLECTION).where('uid', '==', firebase.auth().currentUser.uid)
                    .get().then(data => {
                    if (!data.empty) {
                        data.forEach(res => {
                            const docId = res.id;
                            firebase.firestore().collection(CHECKINATTENDEE_COLLECTION).doc(docId).update({
                                deviceToken: ''
                            });
                        });
                    }
                });
                firebase.auth().signOut()
                    .then(() => {
                    console.log('LOG Out');
                    resolve();
                }).catch((error) => {
                    reject();
                });
            }
        });
    }
    userDetails() {
        return firebase.auth().currentUser;
    }
    findUserByEmail(email) {
        this.loadingService.present();
        const URL = 'https://us-central1-aiu-event-management.cloudfunctions.net/findUserByEmail';
        const requestBody = {
            email: email
        };
        const header = new HttpHeaders().set('Content-Type', 'application/json');
        const option = {
            headers: header
        };
        return new Promise((resolve, reject) => {
            this.httpClient.post(URL, requestBody, option).subscribe(res => {
                this.loadingService.dismiss();
                resolve(res);
            }, (err) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const action = this.actionSheetCtrl.create({
                    header: 'Email not found!',
                    subHeader: 'No account found for this email address.',
                    buttons: [
                        {
                            text: 'Ok',
                            cssClass: 'ok2'
                        }
                    ],
                    cssClass: 'action'
                });
                this.loadingService.dismiss();
                (yield action).present();
                reject(err);
            }));
        });
    }
}
