/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./app.component";
import * as i7 from "@ionic-native/status-bar/ngx/index";
import * as i8 from "@ionic-native/splash-screen/ngx/index";
import * as i9 from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx/FCM";
import * as i10 from "@ionic-native/local-notifications/ngx/index";
import * as i11 from "@ionic-native/firebase-dynamic-links/ngx/index";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-app", [], null, null, null, i2.View_IonApp_0, i2.RenderType_IonApp)), i1.ɵdid(1, 49152, null, 0, i3.IonApp, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 16777216, null, 0, 1, "ion-router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i3.IonRouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i3.Config, i3.NavController, i5.Location, i1.ElementRef, i4.Router, i1.NgZone, i4.ActivatedRoute, [3, i3.IonRouterOutlet]], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppComponent, [i3.Platform, i7.StatusBar, i8.SplashScreen, i9.FCM, i4.Router, i10.LocalNotifications, i3.ToastController, i11.FirebaseDynamicLinks], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
