// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatIconModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatRadioModule,
  MatProgressBarModule,
  MatButtonModule
} from '@angular/material';
import { AddTicketFormComponent } from './add-ticket-form.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatButtonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  exports: [AddTicketFormComponent],
  declarations: [AddTicketFormComponent],
  entryComponents: []
})
export class AddTicketFormComponentModule { }
