import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';
import { PopoverController, NavController, Platform, ActionSheetController, ModalController } from '@ionic/angular';
import { LogoutPopoverComponent } from '../logout-popover/logout-popover.component';
import { NavExtrasService } from 'src/app/services/nav-extras.service';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { trigger, transition, style, animate } from '@angular/animations';
import { delay } from 'q';
import { environment } from '../../../environments/environment';

const NOTIFICATION_COLLECTION = environment.dev ? 'notifications-dev' : 'notifications';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {

  @Input() homeLogo: boolean;
  @Input() backRoute: string;
  @Input() loginBackRoute: string;
  @Input() loginRoute: string;
  @Input() username: string;
  @Input() pageTitle: string;
  @Input() termsAndPrivacy: boolean;
  @Output() headerDetails = new EventEmitter();

  currentId: string;
  profilePictureURL: string;
  emptyProfilePicture: string = 'assets/images/empty.png';
  profile: any;
  notificationCount: number;
  currentUserId: string;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private popoverCtrl: PopoverController,
    private navCtrl: NavController,
    private navExtraService: NavExtrasService,
    private platform: Platform,
    private storage: Storage,
    private actionSheet: ActionSheetController,
    private afs: AngularFirestore,
    private modalCtrl: ModalController
  ) {
    this.getUser();
    this.initializeApp();
    this.getNotificationCount();
  }

  dropdownMenu = false;
  desktop = false;
  mobile = false;
  admin = false;
  showMng = false;

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('desktop')) {
        this.desktop = true;
      }
      else if (this.platform.is('mobile')) {
        this.mobile = true;
      }
    });
  }

  async ngAfterViewInit() {
    // check admin role
    await this.getUser();
    this.afs.collection('profile', ref => ref.where('uid', '==', this.currentId))
      .snapshotChanges().subscribe(data => {
        this.profile = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data()
          } as any;
        });
        if (this.profile[0].role === 'admin') {
          this.admin = true;
        }
      });
  }

  login() {
    this.navExtraService.setExtras({ backRoute: this.loginBackRoute, loginRoute: this.loginRoute });
    this.navCtrl.navigateRoot('login');
  }

  async logout() {
    await this.storage.clear();
    const user = await this.firebaseService.firebaseOnAuth();
    const FIRESTORE = firebase.firestore();
    this.currentUserId = user.uid;

    let profileDocId = '';
    const getProfileDocId = await this.afs.collection('profile', query => query.where('uid', '==', this.currentUserId))
      .get().toPromise().then(async (res) => {
        // console.log('response', res);

        if (!res.empty) {
          res.forEach(data => {
            profileDocId = data.id;
          });
          const updateProfileFCM = await FIRESTORE.collection('profile').doc(profileDocId)
            .update({
              deviceToken: null
            });
        }
        else {
          console.log('EMPTY');
        }
      });
    console.log('loginBack', this.loginBackRoute);
    if (this.loginBackRoute === 'attendee' || 'recent-chat' || 'polls' || 'download' || !this.loginBackRoute) {
      this.loginBackRoute = 'event-home';
    }
    this.firebaseService.signOut(this.loginBackRoute, this.loginRoute);
  }

  logInBack() {
    console.log('backRoute:', this.backRoute);
    this.navCtrl.navigateRoot(this.backRoute);
  }

  back() {
    console.log('back', this.backRoute);
    if (!this.termsAndPrivacy) {
      this.router.navigateByUrl(this.backRoute);
    }
    else {
      this.modalCtrl.dismiss();
    }
  }

  async presentPopover(ev) {
    console.log(ev);
    const popover = this.popoverCtrl.create({
      component: LogoutPopoverComponent,
      componentProps: {
        loginBackRoute: this.loginBackRoute,
        loginRoute: this.loginRoute
      },
      event: ev,
    });
    (await popover).present();
  }

  showMenu() {
    this.dropdownMenu = !this.dropdownMenu;
  }

  async getUser() {
    const user = await this.firebaseService.firebaseOnAuth();
    if (user) {
      this.currentId = user.uid;
      this.profilePictureURL = user.photoURL ? user.photoURL : this.emptyProfilePicture;
      this.headerDetails.emit({
        userId: this.currentId,
        profilePicture: this.profilePictureURL,
        username: user.displayName
      });
    }

    return user;
  }

  async getNotificationCount() {
    this.notificationCount = 0;
    const userId = (await this.firebaseService.firebaseOnAuth()).uid;

    const notifications = await this.afs.collection<any>(NOTIFICATION_COLLECTION, query =>
      query.where('userId', '==', userId));

    notifications.snapshotChanges().forEach(data => {
      this.notificationCount = 0;
      data.map(res => {
        const notificationStatus = res.payload.doc.data().isRead;
        // console.log('status', notificationStatus);

        if (!notificationStatus) {
          this.notificationCount++;
        }
      });
    });
  }

  goToProfileTicket() {
    this.router.navigateByUrl('profile-ticket');
    this.dropdownMenu = false;
  }

  goToNotifications() {
    this.router.navigateByUrl('notification');
    this.dropdownMenu = false;
  }

  goToProfile() {
    this.router.navigateByUrl('profile');
    this.dropdownMenu = false;
  }

  goToEvent() {
    this.router.navigateByUrl('admin/events');
    this.dropdownMenu = false;
  }

  goToVenue() {
    this.router.navigateByUrl('admin/venues');
    this.dropdownMenu = false;
  }

  goToBanner() {
    this.router.navigateByUrl('admin/banners');
    this.dropdownMenu = false;
  }

  goToPush() {
    this.router.navigateByUrl('admin/push-notification');
    this.dropdownMenu = false;
  }

  goToCalendar() {
    this.router.navigateByUrl('admin/calendar');
    this.dropdownMenu = false;
  }

  navigateHome() {
    this.navCtrl.navigateRoot('event-home');
    this.dropdownMenu = false;
  }
}
